<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag='router-link' to="" active>Insurance Transactions</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 83vh">
      <div class="columns" style="margin-top: 10px">
        <b-field class="column is-one-fifths is-small" label="From" label-position="on-border">
          <b-datetimepicker v-model="fromDate" @input="onFromDateChange()" size="is-small" :show-week-number="false"
            locale="en-CA" placeholder="Click to select..." icon="calendar-today" trap-focus>
          </b-datetimepicker>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="To" label-position="on-border">
          <b-datetimepicker v-model="toDate" @input="filterChanged = true" size="is-small" :min-date="fromDate"
            :show-week-number="false" locale="en-CA" placeholder="Click to select..." icon="calendar-today" trap-focus>
          </b-datetimepicker>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="Country" label-position="on-border">
          <b-select v-model="countryCode" placeholder="Select a Country" size="is-small" expanded>
            <option :value="''" selected>All Countries</option>
            <option v-for="country in countryList" :value="country.code" :key="country.code">{{ country.name }}</option>
          </b-select>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="Fetch Count" label-position="on-border">
          <b-select v-model="fetchCount" placeholder="Select a Count" size="is-small" expanded>
            <option :value="10" selected>10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="99999999">All</option>
          </b-select>
        </b-field>

        <b-field class="column is-one-fifths is-small" label="NIC/Passport/Trace" label-position="on-border">
          <b-input v-model="userUID" size="is-small" expanded></b-input>
        </b-field>

        <b-field class="column" label="">
          <div class="buttons">
            <b-button size="is-small" type="is-success" @click="searchData">Search</b-button>
            
            <b-tooltip label="Clear All Filters" style="margin-right: 5px">
              <b-button size="is-small" :disabled="!searched || filterChanged" @click="clearFilters" type="is-danger" icon-right="close"></b-button>
            </b-tooltip>
            <b-tooltip label="Download as Excel">
              <b-button size="is-small" :disabled="filterChanged" @click="generateXlsx" type="is-info"
                icon-right="microsoft-excel"></b-button>
            </b-tooltip>
            <b-tooltip label="Download as PDF" style="margin-left: 5px">
              <b-button size="is-small" :disabled="filterChanged" @click="generatePdf" type="is-info"
                icon-right="file-pdf-box"></b-button>
            </b-tooltip>
          </div>
        </b-field>
      </div>

      <b-table :data="filteredList" :height="vhToPixels(55)" :paginated="tableOptions.isPaginated"
        :per-page="tableOptions.perPage" :current-page.sync="tableOptions.currentPage"
        :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection"
        :pagination-rounded="tableOptions.isPaginationRounded" :sort-icon="tableOptions.sortIcon"
        :sort-icon-size="tableOptions.sortIconSize" default-sort="id" aria-next-label="Next page"
        aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
        :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput"
        :pagination-order="tableOptions.paginationOrder" :page-input-position="tableOptions.inputPosition"
        :debounce-page-input="tableOptions.inputDebounce">

        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="uid_no" label="UID No" sortable v-slot="props" width="200">
          <div style="width: 100px">{{ props.row.uid }}</div>
        </b-table-column>

        <b-table-column field="trace_number" label="Trace Number" sortable v-slot="props" width="200">
          {{ props.row.trace }}
        </b-table-column>

        <b-table-column field="nic_no" label="NIC Number" sortable v-slot="props" width="200">
          {{ props.row.nic }}
        </b-table-column>

        <!-- <b-table-column field="mobile_number" label=" Mobile Number" sortable v-slot="props" width="200">
          {{ props.row.mobile_number }}
        </b-table-column> -->

        <b-table-column field="email" label=" Email" sortable v-slot="props" width="200">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="dependents_detail" label=" Dependents Detail" sortable v-slot="props" width="200">
          <b-collapse :open="false" aria-id="contentIdForA11y1">
            <template #trigger="trigger">
              <b-tooltip label="Click to view">
                <b-button label="" icon-right="arrow-down-drop-circle-outline" size="is-small" type="is-primary"
                  aria-controls="contentIdForA11y1" :aria-expanded="trigger.open" />
              </b-tooltip>
            </template>

            <b-collapse class="card" animation="slide" v-for="(beneficiary, index) in props.row.dependants"
              :key="beneficiary.id"
              :aria-id="'contentIdForA11y5-' + index">

            <!-- <b-collapse class="card" animation="slide" v-for="(beneficiary, index) in props.row.dependants"
              :key="beneficiary.id" :open="isOpen == index" @open="isOpen = index"
              :aria-id="'contentIdForA11y5-' + index"> -->
              <template #trigger="props">
                <div class="card-header" role="button" :aria-controls="'contentIdForA11y5-' + index"
                  :aria-expanded="props.open">
                  <p class="card-header-title">
                    {{ beneficiary.name }}
                  </p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
              </template>
              <div class="card-content">
                <div class="content">
                  <p><strong>Title:</strong> {{ beneficiary.title }}</p>
                  <p><strong>NIC Number:</strong> {{ beneficiary.nic_number }}</p>
                  <p><strong>Relation Type:</strong> {{ beneficiary.relation_type }}</p>
                  <p><strong>Mobile Number:</strong> {{ beneficiary.mobile_number }}</p>
                  <p><strong>Email:</strong> {{ beneficiary.email }}</p>
                  <p><strong>User ID:</strong> {{ beneficiary.user_id }}</p>
                </div>
              </div>
            </b-collapse>
          </b-collapse>
        </b-table-column>


        <b-table-column field="country" label="Country" sortable v-slot="props" width="200">
          <div style="width: 100px">{{ props.row.country }}</div>
        </b-table-column>


        <b-table-column field="cover_package" label="Cover Package" sortable v-slot="props" width="200">
          {{ props.row.cover_package }}
        </b-table-column>

    
        <b-table-column field="plan_no" label="Plan No" sortable v-slot="props" width="200">
          {{ props.row.plan_no }}
        </b-table-column>

        <b-table-column field="currency_code" label="Currency Code" sortable v-slot="props" width="200">
          {{ props.row.currency_code }}
        </b-table-column>


       <b-table-column field="amount" label="Plan Amount" sortable v-slot="props" width="200">
          {{ props.row.insurance_package_price }} USD
        </b-table-column> 

        <b-table-column field="amount" label="Total Amount" sortable v-slot="props" width="200">
          {{ props.row.amount }} USD
        </b-table-column>



        <b-table-column field="initiated_time" label="Tran Initiated Time" sortable v-slot="props">
          <span class="tag is-success expanded-col">{{ getDateTimeForDisplaying(props.row.initiated_time) }}</span>
        </b-table-column>

       
        <b-table-column field="card_payment_time" label="Card Payment Time" sortable v-slot="props">
          <span class="tag is-success expanded-col">{{ getDateTimeForDisplaying(props.row.card_payment_time) }}</span>
        </b-table-column>

        <b-table-column width="200" field="card_payment_status" label="Card Payment Status" sortable v-slot="props">
          <p style="width: 150px">{{ props.row.card_payment_status }}</p>
        </b-table-column>

        <b-table-column field="card_payment_description" label="Card Payment Description" sortable v-slot="props"
          width="200">
          {{ props.row.card_payment_description }}
        </b-table-column>

        <b-table-column field="ipg_transaction_id" label="IPG Transaction ID" sortable v-slot="props">
          {{ props.row.ipg_transaction_id }}
        </b-table-column>

        <b-table-column field="ipg_data" label="IPG Details" sortable v-slot="props">
          <b-collapse :open="false" aria-id="contentIdForA11y1">
            <template #trigger="trigger">
              <b-tooltip label="Click to view">
                <b-button label="" icon-right="arrow-down-drop-circle-outline" size="is-small" type="is-primary"
                  aria-controls="contentIdForA11y1" :aria-expanded="trigger.open" />
              </b-tooltip>
            </template>
            <div class="notification">
              <div class="content" style="width: 20rem">
                <h5>
                  IPG Deduction Details
                </h5>
                <section class="card-content">
                  <div class="content pt-auto">
                    <b-field label="Exchange Rate (LKR)" custom-class="is-small">
                      <b-input :value="props.row.exchange_rate_lkr" size="is-small" readonly></b-input>
                    </b-field>
                    <b-field label="IPG Rate (%)" custom-class="is-small">
                      <b-input :value="props.row.ipg_rate" size="is-small" readonly></b-input>
                    </b-field>
                    <b-field :label="'IPG Commission (' + props.row.currency_code + ')'" custom-class="is-small">
                      <b-input :value="numberWithCommas(props.row.ipg_commission_lkr)" size="is-small" readonly></b-input>
                    </b-field>
                    <b-field :label="'Bank Charge (' + props.row.currency_code + ')'" custom-class="is-small">
                      <b-input :value="props.row.bank_charge_lkr" size="is-small" readonly></b-input>
                    </b-field>
                    <b-field :label="'Ceylinco General Reserved Amount (' + props.row.currency_code + ')'" custom-class="is-small">
                      <b-input :value="props.row.ceylinco_general_reserved_amount" size="is-small" readonly></b-input>
                    </b-field>
                    <!-- <b-field label="Refund Commission (%)" custom-class="is-small">
                      <b-input :value="props.row.refund_commission" size="is-small" readonly></b-input>
                    </b-field> -->
                  </div>
                </section>
              </div>
            </div>
          </b-collapse>
        </b-table-column>

        <b-table-column field="insurance_payment_time" label="Insurance Payment Time" sortable v-slot="props"
          width="200">
          {{ props.row.insurance_payment_time }}
        </b-table-column>

        <b-table-column field="insurance_payment_status" label="Insurance Payment Status" sortable v-slot="props"
          width="200">
          {{ props.row.insurance_payment_status }}
        </b-table-column>

        <b-table-column field="insurance_payment_description" label="Insurance Payment Description" sortable
          v-slot="props" width="200">
          {{ props.row.insurance_payment_description }}
        </b-table-column>

        <b-table-column field="insurance_transaction_id" label="Insurance Transaction ID" sortable v-slot="props"
          width="200">
          {{ props.row.insurance_transaction_id }}
        </b-table-column>

        <b-table-column field="ipg_void_payment_time" label="IPG Void Payment Time" sortable v-slot="props" width="200">
          <span class="tag is-success expanded-col">{{ getDateTimeForDisplaying(props.row.ipg_void_payment_time)
            }}</span>
        </b-table-column>

        <b-table-column field="ipg_void_status" label="IPG Void Status" sortable v-slot="props" width="200">
          {{ props.row.ipg_void_status }}
        </b-table-column>

        <!-- <b-table-column field="ipg_void_tran_id" label="IPG Void Tran ID" sortable v-slot="props" width="200">
          {{ props.row.ipg_void_tran_id }}
        </b-table-column> -->


        <b-table-column field="created_at" label="Created At" sortable centered v-slot="props">
          <span class="tag is-success expanded-col">
            {{ getDateTimeForDisplaying(props.row.created_at) }}
          </span>
        </b-table-column>

        <b-table-column field="updated_at" label="Updated At" sortable centered v-slot="props">
          <span class="tag is-success expanded-col">
            {{ getDateTimeForDisplaying(props.row.updated_at) }}
          </span>
        </b-table-column>



      </b-table>
    </div>
    <br />
  </section>
</template>

<script>
import NetworkManager from "@/network"
import { appSettings } from "@/appSettings"
import { TransactionExportWizard } from "@/views/Reports/Supports";

export default {
  name: 'InsuranceTransactions',
  components: {
  },
  data() {
    return {
      fromDate: new Date(),
      toDate: new Date(),
      filterChanged: false,
      beneficiarys: [],
      countryCode: '',
      countryList: [],
      transactions: [],
      tableHeaders: [],
      filteredList: [],
      userUID: null,
      fetchCount: 10,
      searched: false,
      exportWizard: new TransactionExportWizard("Insurance Transactions"),

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 50,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },

      tableHeaders: []
    }
  },

  computed: {
  },

  watch: {
    fromDate(newVal, oldVal) {
      this.filterChanged = true
    },

    toDate(newVal, oldVal) {
      this.filterChanged = true
    }
  },

  methods: {

    searchData: function () {
    this.searched = true,
    this.loadData()  
    },

    clearFilters: function () {
      const self = this

      self.fromDate = new Date();
      self.fromDate.setHours(0, 0, 1, 0);

      self.toDate = new Date();
      self.toDate.setHours(23, 59, 59, 999);

      self.fetchCount = 10,
      self.countryCode = ''
      self.userUID = null
      self.filterChanged = false;
      self.searched = false;

      self.loadData();
    },
    getImageUrl: function (path) {
      return appSettings.$api_url + path + "?" + new Date().getTime()
    },

    generateXlsx: function () {
      const self = this

      // Format the dependants data
      const formattedData = self.filteredList.map(item => {
        const formattedItem = { ...item }
        formattedItem.dependants = item.dependants.map(beneficiary =>
          ` ${beneficiary.title} ${beneficiary.name} (${beneficiary.relation_type}, ${beneficiary.email}, ${beneficiary.mobile_number})`
        ).join('\n')
        return formattedItem
      })

      self.exportWizard.exportToXlsx(
        new Date(),
        self.authUser().username,
        self.fromDate,
        self.toDate,
        "insuranceTransactions_" + self.formatDateTime(new Date()),
        self.tableHeaders,
        formattedData
      )
    },

    generatePdf: function () {
      const self = this

      // Format the dependants data
      const formattedData = self.filteredList.map(item => {
        const formattedItem = { ...item }
        formattedItem.dependants = item.dependants.map(beneficiary =>
          `${beneficiary.title} ${beneficiary.name} (${beneficiary.relation_type}, ${beneficiary.email}, ${beneficiary.mobile_number})`
        ).join('\n')
        return formattedItem
      })

      self.exportWizard.exportToPDF(
        new Date(),
        self.authUser().username,
        self.fromDate,
        self.toDate,
        "insuranceTransactions_" + self.formatDateTime(new Date()),
        self.tableHeaders,
        formattedData
      )
    },

    onFromDateChange: function () {
      const self = this

      if (self.toDate.getTime() < self.fromDate.getTime()) {
        self.toDate = self.fromDate
      }
    },

    loadData: function () {
      const self = this

      self.fromDate.setSeconds(1)
      self.toDate.setSeconds(0)

      let data = {
        from_date: self.formatDateTime(self.fromDate),
        to_date: self.formatDateTime(self.toDate),
        country_code: self.countryCode,
        data: self.userUID && self.userUID.trim() !== '' ? self.userUID : null ,
        fetchCount: self.fetchCount,
      }

      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/Transaction/insuranceTransactions', data, function (e) {
        if (e.statusCode === 200) {
          self.tableHeaders = e.data.fields
          self.filteredList = e.data.filteredList
          self.filteredTotalAmount = e.data.filteredTotalAmount
          self.countryList = e.data.countries
          self.filterChanged = false
        }

        self.hideFullScreenLoader()
      })
    },

  },

  mounted() {
    let fromDate = new Date()
    fromDate.setHours(0)
    fromDate.setMinutes(0)
    fromDate.setSeconds(0)

    let toDate = new Date()
    toDate.setHours(23)
    toDate.setMinutes(59)
    toDate.setSeconds(59)

    this.fromDate = fromDate
    this.toDate = toDate

    this.loadData()
  }
}
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}

.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
</style>
