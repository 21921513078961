<template>
  <section>

    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag='router-link' to="" active>KYC User Information</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 83vh">
      <div class="columns" style="margin-top: 10px">
        <b-field class="column is-one-fifths is-small" label="UID/Email/Contact Number" label-position="on-border">
          <b-input v-model="searchParams.data" @blur="searchParams.data = searchParams.data.trim()" maxlength="30"
            size="is-small"></b-input>
        </b-field>
        <b-field class="column is-small" label="KYC Status" label-position="on-border">
          <b-select v-model="searchParams.kycStatus" placeholder="Select a type" size="is-small" expanded>
            <option :value="'PENDING'" selected>Pending</option>
            <option :value="'APPROVED'">Approved</option>
            <option :value="'REJECTED'">Rejected</option>
            <option :value="''">All</option>
          </b-select>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="Fetch Count" label-position="on-border">
          <b-select v-model="searchParams.fetchCount" placeholder="Select a Count" size="is-small" expanded>
            <option :value="10" selected>10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="99999999">All</option>
          </b-select>
        </b-field>
        <b-field class="column" label="">
          <div class="buttons">
            <b-button size="is-small" type="is-success" @click="loadData">Search</b-button>
          </div>
        </b-field>
      </div>
      <b-table :height="vhToPixels(50)" :data="KYCUsers" :paginated="tableOptions.isPaginated"
        :per-page="tableOptions.perPage" :current-page.sync="tableOptions.currentPage"
        :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection"
        :pagination-rounded="tableOptions.isPaginationRounded" :sort-icon="tableOptions.sortIcon"
        :sort-icon-size="tableOptions.sortIconSize" default-sort="id" aria-next-label="Next page"
        aria-previous-label="Previous page" aria-page-label="Page" aria-current-label="Current page"
        :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput"
        :pagination-order="tableOptions.paginationOrder" :page-input-position="tableOptions.inputPosition"
        :debounce-page-input="tableOptions.inputDebounce">

        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
          {{ props.row.kycId }}
        </b-table-column>

        <b-table-column field="firstName" label="First Name" sortable v-slot="props" width="200">
          {{ props.row.first_name }}
        </b-table-column>

        <b-table-column field="lastName" label="Last Name" sortable v-slot="props" width="200">
          {{ props.row.last_name }}
        </b-table-column>

        <b-table-column field="uidType" label="UID Type" sortable v-slot="props" width="200">
          {{ props.row.uid_type }}
        </b-table-column>

        <b-table-column field="uid" label="UID" sortable v-slot="props" width="200">
          {{ props.row.uid }}
        </b-table-column>

        <b-table-column field="contact" label="Contact Number" sortable v-slot="props" width="200">
          {{ props.row.phone_no }}
        </b-table-column>

        <b-table-column field="email" label="Email" sortable v-slot="props" width="200">
          {{ props.row.email }}
        </b-table-column>

        <b-table-column field="disabled" label="KYC Status" sortable v-slot="props">
          <b-tag :type="getKycStatusClass(props.row.status)" class="is-light">
            {{ props.row.status }}
          </b-tag>
        </b-table-column>

        <b-table-column field="created_at" label="Created At" sortable centered v-slot="props">
          <span class="tag is-success">
            {{ new Date(props.row.created_at).toLocaleString() }}
          </span>
        </b-table-column>

        <b-table-column field="updated_at" label="Updated At" sortable centered v-slot="props">
          <span class="tag is-success">
            {{ new Date(props.row.updated_at).toLocaleString() }}
          </span>
        </b-table-column>

        <b-table-column label="Actions" v-slot="props" width="200">
          <b-button @click="openModal(props.row)" size="is-small" type="is-warning">Review</b-button>
        </b-table-column>

        <b-table-column label="Sanction Results" v-slot="props" width="200">
          <b-button @click="SanctionView(props.row.kycId)" size="is-small" type="is-link"
            :disabled="!props.row.sanction_search">View</b-button>
        </b-table-column>
      </b-table>
    </div>
    <br />
    <!--sanction view modal-->
    <b-modal v-model="isKycSanctionModalActive" has-modal-card trap-focus :can-cancel="false" aria-modal>
      <div class="modal-card">
        <header class="modal-card-head has-text-centered">
          <p class="modal-card-title">Sanction Results</p>
          <button class="delete is-pulled-right" @click="isKycSanctionModalActive = false"></button>
        </header>
        <section class="modal-card-body">
          <div v-if="!Sanctions_Result || !Sanctions_Result.length" class="has-text-centered">
            <p>No Data Available</p>
          </div>
          <div v-else>
            <p class="mb-4"><strong>NO. of Sources:</strong> {{ Sanctions_Result.length }}</p>
            <div v-for="(result, index) in Sanctions_Result" :key="index" class="box mb-4">
              <p><strong>Source:</strong> {{ index + 1 }}</p>
              <table class="table is-striped is-fullwidth">
                <tbody>
                  <tr>
                    <td><strong>Name</strong></td>
                    <td>{{ result.name || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Gender</strong></td>
                    <td>{{ result.gender || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Date of Birth</strong></td>
                    <td>{{ result.date_of_birth || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alias Names</strong></td>
                    <td>{{ result.alias_names ? result.alias_names.join(', ') : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Associated Country</strong></td>
                    <td>{{ result.associated_country ? result.associated_country.join(', ') : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Sanctioned Country</strong></td>
                    <td>{{ result.sanctionedCountry ? result.sanctionedCountry.join(', ') : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>President</strong></td>
                    <td>{{ result.president || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Aircraft Details</strong></td>
                    <td>{{ result.aircraft_details || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Source Name</strong></td>
                    <td>{{ result.source_name || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Source Type</strong></td>
                    <td>{{ result.source_type || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Source Link</strong></td>
                    <td><a :href="result.source_link" target="_blank">{{ result.source_link || 'N/A' }}</a></td>
                  </tr>
                  <tr>
                    <td><strong>Source Description</strong></td>
                    <td>{{ result.source_description || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Zipcode</strong></td>
                    <td>{{ result.zipcode || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Country of Jurisdiction</strong></td>
                    <td>{{ result.country_of_jurisdiction || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Headquarters</strong></td>
                    <td>{{ result.headquarters || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Parent Company</strong></td>
                    <td>{{ result.parent_company || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Flag State</strong></td>
                    <td>{{ result.flag_state || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Call Sign</strong></td>
                    <td>{{ result.call_sign || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tonnage</strong></td>
                    <td>{{ result.tonnage || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Model</strong></td>
                    <td>{{ result.model || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Name Match Score</strong></td>
                    <td>{{ result.name_match_score || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Overall Data Match score</strong></td>
                    <td>{{ result.sams_score?.overall_score || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Matched Address</strong></td>
                    <td>{{ result.matched_address || 'N/A' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot"  style="justify-content: flex-end;">
          <b-button type="is-primary" @click="isKycSanctionModalActive = false">Close</b-button>
        </footer>
      </div>
    </b-modal>
    <b-modal v-model="isKycModalActive" title="KYC Information" width="800px" :can-cancel="false" full-screen>
      <template #default>
        <header class="modal-card-head">
          <p class="modal-card-title">KYC Information <b-tag>{{ KYCUser.uid }}</b-tag> <b-tag style="margin-left: 10px"
              :type="getKycStatusClass(KYCUser.status)" class="is-light"> {{
                KYCUser.status }}</b-tag></p>
          <button type="button" class="delete" @click="cancelKYC" />
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-half"
              v-if="submittedData && KYCUser.created_at.slice(0, 16) !== KYCUser.updated_at.slice(0, 16)"
              style="padding: 0 20px;">
              <br><br>
              <p class="subtitle">Personal Details</p>
              <div class="columns">
                <div class="column is-three-fifths">
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(0, -13).map(key => ({ [key]: submittedData[key] })))"
                    :key="key">
                    <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                      <b-input :value="field.value" readonly></b-input>
                    </b-field>
                  </div>
                  <div class="columns mt-0 mb-0">
                    <div
                      v-for="(field, key, index) in Object.assign({}, ...Object.keys(submittedData).slice(2, -11).map(key => ({ [key]: submittedData[key] })))"
                      :key="key" class="column" :class="{ 'is-one-quarter': index === 0, '': index !== 0 }"
                      :style="{ width: index === 0 ? '120px' : 'auto' }">
                      <b-field :label="toTitleCase(field.title)" :label-position="labelPosition">
                        <b-input :value="field.value" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(4, -3).map(key => ({ [key]: submittedData[key] })))"
                    :key="key">
                    <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                      <b-input :value="field.value" readonly></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="column switches-column">
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(0, -13).map(key => ({ [key]: submittedData[key] })))"
                    :key="key">
                    <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4"
                      disabled>
                      <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                        {{ field.approved ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                  <div class="field-and-switch">
                    <b-switch v-model="submittedContactApprove"
                      :type="submittedContactApprove ? 'is-success' : 'is-danger'" class="m-4" disabled>
                      <span style="font-weight: bold"
                        :class="submittedContactApprove ? 'has-text-success' : 'has-text-danger'">
                        {{ submittedContactApprove ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(submittedData).slice(4, -3).map(key => ({ [key]: submittedData[key] })))"
                    :key="key">
                    <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4"
                      disabled>
                      <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                        {{ field.approved ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                </div>
              </div>
              <hr class="mb-4 mt-0" style="background-color: #9c9c9c;border: none;height: 0.5px;">
              <h4 class="subtitle">Personal Documents</h4><br>
              <div class="columns mb-0">
                <b-field class="column" label="UID Front" :label-position="labelPosition">
                  <img
                    :src="submittedData.uidFrontImage.value ? getImageUrl(submittedData.uidFrontImage.value) : require('@/assets/Placeholder Image.png')"
                    style="border: 1px solid blue; width: 400px;">
                </b-field>
                <b-field class="column" label="UID Back" :label-position="labelPosition">
                  <img
                    :src="submittedData.uidBackImage.value ? getImageUrl(submittedData.uidBackImage.value) : require('@/assets/Placeholder Image.png')"
                    style="border: 1px solid blue; width: 400px;">
                </b-field>
              </div>
              <div class="columns">
                <div class="column">
                  <b-switch v-model="submittedData.uidFrontImage.approved"
                    :type="submittedData.uidFrontImage.approved ? 'is-success' : 'is-danger'" disabled>
                    <span style="font-weight: bold"
                      :class="submittedData.uidFrontImage.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                        submittedData.uidFrontImage.approved === true ? 'Approve' : 'Reject' }}</span>
                  </b-switch>
                </div>
                <div class="column">
                  <b-switch v-model="submittedData.uidBackImage.approved"
                    :type="submittedData.uidBackImage.approved === true ? 'is-success' : 'is-danger'" disabled>
                    <span style="font-weight: bold"
                      :class="submittedData.uidBackImage.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                        submittedData.uidBackImage.approved === true ? 'Approve' : 'Reject' }}</span>
                  </b-switch>
                </div>
              </div>
              <div class="columns mt-4">
                <b-field class="column" label="Selfie" :label-position="labelPosition">
                  <img
                    :src="submittedData.userImage.value ? getImageUrl(submittedData.userImage.value) : require('@/assets/Placeholder Image.png')"
                    alt="Placeholder Image" style="border: 1px solid blue; width: 300px;">

                </b-field>
              </div>
              <div class="columns">
                <div class="column">
                  <b-switch v-model="submittedData.userImage.approved"
                    :type="submittedData.userImage.approved === true ? 'is-success' : 'is-danger'" disabled>
                    <span style="font-weight: bold"
                      :class="submittedData.userImage.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                        submittedData.userImage.approved === true ? 'Approve' : 'Reject' }}</span>
                  </b-switch>
                </div>
              </div>
            </div>
            <div class="with-vertical-line"
              v-if="submittedData && KYCUser.created_at.slice(0, 16) !== KYCUser.updated_at.slice(0, 16)"></div>
            <div class="column" style="padding: 0 20px;">
              <p class="subtitle is-5" style="color:rgba(0, 0, 0, 0.349)">Submitted Information</p>
              <p class="subtitle">Personal Details</p>
              <!-- submitted fields -->
              <div class="columns">
                <div class="column is-three-fifths">
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(0, -13).map(key => ({ [key]: currentData[key] })))"
                    :key="key">
                    <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                      <b-input :value="field.value" readonly></b-input>
                    </b-field>
                  </div>
                  <div class="columns mt-0 mb-0">
                    <div
                      v-for="(field, key, index) in Object.assign({}, ...Object.keys(currentData).slice(2, -11).map(key => ({ [key]: currentData[key] })))"
                      :key="key" class="column" :class="{ 'is-one-quarter': index === 0, '': index !== 0 }"
                      :style="{ width: index === 0 ? '120px' : 'auto' }">
                      <b-field :label="toTitleCase(field.title)" :label-position="labelPosition">
                        <b-input :value="field.value" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(4, -3).map(key => ({ [key]: currentData[key] })))"
                    :key="key">
                    <b-field :label="toTitleCase(field.title)" :label-position="labelPosition" class="mt-2 mb-2">
                      <b-input :value="field.value" readonly></b-input>
                    </b-field>
                  </div>
                </div>
                <div class="column switches-column">
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(0, -13).map(key => ({ [key]: currentData[key] })))"
                    :key="key">
                    <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4" :disabled="isSanctionRejected">
                      <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                        {{ field.approved ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                  <div class="field-and-switch">
                    <b-switch v-model="contactApprove" :type="contactApprove ? 'is-success' : 'is-danger'" class="m-4"
                      @input="toggleContactApprove(currentData)" :disabled="isSanctionRejected">
                      <span style="font-weight: bold" :class="contactApprove ? 'has-text-success' : 'has-text-danger'">
                        {{ contactApprove ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(4, -9).map(key => ({ [key]: currentData[key] })))"
                    :key="key">
                    <b-switch v-model="collectiveApprovalStatus"
                      :type="collectiveApprovalStatus ? 'is-success' : 'is-danger'" class="m-4"
                      @input="updateRelatedApprovalStatus" :disabled="isSanctionRejected">
                      <span style="font-weight: bold"
                        :class="collectiveApprovalStatus ? 'has-text-success' : 'has-text-danger'">
                        {{ collectiveApprovalStatus ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(6, -7).map(key => ({ [key]: currentData[key] })))"
                    :key="key">
                    <b-switch v-model="field.approved" :type="field.approved ? 'is-success' : 'is-danger'" class="m-4" :disabled="isSanctionRejected">
                      <span style="font-weight: bold" :class="field.approved ? 'has-text-success' : 'has-text-danger'">
                        {{ field.approved ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>
                  <div class="field-and-switch"
                    v-for="(field, key) in Object.assign({}, ...Object.keys(currentData).slice(8, -3).map(key => ({ [key]: currentData[key] })))"
                    :key="key">
                    <b-switch v-model="collectiveAddressApprovalStatus"
                      :type="collectiveAddressApprovalStatus ? 'is-success' : 'is-danger'" class="m-4"
                      @input="updateRelatedAddressApprovalStatus" :disabled="isSanctionRejected">
                      <span style="font-weight: bold"
                        :class="collectiveAddressApprovalStatus ? 'has-text-success' : 'has-text-danger'">
                        {{ collectiveAddressApprovalStatus ? 'Approve' : 'Reject' }}
                      </span>
                    </b-switch>
                  </div>


                </div>
              </div>
              <hr class="mb-4 mt-0" style="background-color: #9c9c9c;border: none;height: 0.5px;">
              <h4 class="subtitle">Personal Documents</h4><br>
              <div class="columns mb-0">
                <b-field class="column" label="UID Front" :label-position="labelPosition">
                  <img
                    :src="currentData.uidFrontImage.value ? getImageUrl(currentData.uidFrontImage.value) : require('@/assets/Placeholder Image.png')"
                    style="border: 1px solid blue; width: 400px;">
                </b-field>
                <b-field class="column" label="UID Back" :label-position="labelPosition">
                  <img
                    :src="currentData.uidBackImage.value ? getImageUrl(currentData.uidBackImage.value) : require('@/assets/Placeholder Image.png')"
                    style="border: 1px solid blue; width: 400px;">
                </b-field>
              </div>
              <div class="columns">
                <div class="column">
                  <b-switch v-model="collectiveApprovalStatus"
                    :type="collectiveApprovalStatus ? 'is-success' : 'is-danger'" @input="updateRelatedApprovalStatus" :disabled="isSanctionRejected">
                    <span style="font-weight: bold"
                      :class="collectiveApprovalStatus === true ? 'has-text-success' : 'has-text-danger'">{{
                        collectiveApprovalStatus === true ? 'Approve' : 'Reject' }}</span>
                  </b-switch>
                </div>
                <div class="column">
                  <b-switch v-model="collectiveApprovalStatus"
                    :type="collectiveApprovalStatus === true ? 'is-success' : 'is-danger'"
                    @input="updateRelatedApprovalStatus" :disabled="isSanctionRejected">
                    <span style="font-weight: bold"
                      :class="collectiveApprovalStatus === true ? 'has-text-success' : 'has-text-danger'">{{
                        collectiveApprovalStatus === true ? 'Approve' : 'Reject' }}</span>
                  </b-switch>
                </div>
              </div>
              <div class="columns mt-4">
                <b-field class="column" label="Selfie" :label-position="labelPosition">
                  <img
                    :src="currentData.userImage.value ? getImageUrl(currentData.userImage.value) : require('@/assets/Placeholder Image.png')"
                    style="border: 1px solid blue; width: 300px;">
                </b-field>
              </div>
              <div class="columns">
                <div class="column">
                  <b-switch v-model="currentData.userImage.approved"
                    :type="currentData.userImage.approved === true ? 'is-success' : 'is-danger'" :disabled="isSanctionRejected">
                    <span style="font-weight: bold"
                      :class="currentData.userImage.approved === true ? 'has-text-success' : 'has-text-danger'">{{
                        currentData.userImage.approved === true ? 'Approve' : 'Reject' }}</span>
                  </b-switch>
                </div>
              </div>
            </div>
          </div>
          <p>Sanction Status: <span class="has-text-danger">{{ sanctionStatusLabel }}</span></p>
        </section>
        <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
          <b-button type="is-link" :loading="isLoading" @click="SanctionDataView()"
            :disabled="!canApproveKyc">Check Sanction Results</b-button>
          <b-button type="is-success" :loading="buttonsLoading" v-if="canApproveKyc && !sanctionFailed"
            @click="approveKyc = true" :disabled="KYCUser.sanction_status != 'APPROVED'">Approve</b-button>
          <b-button type="is-danger" :loading="buttonsLoading"
            v-if="!canApproveKyc || KYCUser.sanction_status === 'REJECTED'" @click="rejectKyc = true">Reject</b-button>
          <b-button @click="cancelKYC">Cancel</b-button>
        </footer>
        <!-- Sanction modal -->
        <b-modal v-model="isKycSanctionModalActive" has-modal-card trap-focus :can-cancel="false" aria-modal>
      <div class="modal-card">
        <header class="modal-card-head has-text-centered">
          <p class="modal-card-title">Sanction Results</p>
          <button v-if="sanction_status === 'APPROVED' || sanction_status === 'REJECTED'" class="delete is-pulled-right" @click="isKycSanctionModalActive = false"></button>
        </header>
        <section class="modal-card-body">
          <div v-if="!Sanctions_Result || !Sanctions_Result.length" class="has-text-centered">
            <p>No Data Available</p>
          </div>
          <div v-else>
            <p class="mb-4"><strong>NO. of Sources:</strong> {{ Sanctions_Result.length }}</p>
            <div v-for="(result, index) in Sanctions_Result" :key="index" class="box mb-4">
              <p><strong>Source:</strong> {{ index + 1 }}</p>
              <table class="table is-striped is-fullwidth">
                <tbody>
                  <tr>
                    <td><strong>Name</strong></td>
                    <td>{{ result.name || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Gender</strong></td>
                    <td>{{ result.gender || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Date of Birth</strong></td>
                    <td>{{ result.date_of_birth || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alias Names</strong></td>
                    <td>{{ result.alias_names ? result.alias_names.join(', ') : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Associated Country</strong></td>
                    <td>{{ result.associated_country ? result.associated_country.join(', ') : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Sanctioned Country</strong></td>
                    <td>{{ result.sanctionedCountry ? result.sanctionedCountry.join(', ') : 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>President</strong></td>
                    <td>{{ result.president || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Aircraft Details</strong></td>
                    <td>{{ result.aircraft_details || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Source Name</strong></td>
                    <td>{{ result.source_name || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Source Type</strong></td>
                    <td>{{ result.source_type || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Source Link</strong></td>
                    <td><a :href="result.source_link" target="_blank">{{ result.source_link || 'N/A' }}</a></td>
                  </tr>
                  <tr>
                    <td><strong>Source Description</strong></td>
                    <td>{{ result.source_description || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Zipcode</strong></td>
                    <td>{{ result.zipcode || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Country of Jurisdiction</strong></td>
                    <td>{{ result.country_of_jurisdiction || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Headquarters</strong></td>
                    <td>{{ result.headquarters || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Parent Company</strong></td>
                    <td>{{ result.parent_company || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Flag State</strong></td>
                    <td>{{ result.flag_state || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Call Sign</strong></td>
                    <td>{{ result.call_sign || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tonnage</strong></td>
                    <td>{{ result.tonnage || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Model</strong></td>
                    <td>{{ result.model || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Name Match Score</strong></td>
                    <td>{{ result.name_match_score || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Overall Data Match score</strong></td>
                    <td>{{ result.sams_score?.overall_score || 'N/A' }}</td>
                  </tr>
                  <tr>
                    <td><strong>Matched Address</strong></td>
                    <td>{{ result.matched_address || 'N/A' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section>
            <footer v-if="isKycModalActive" class="modal-card-foot" style="justify-content: flex-end;">
              <b-button v-if="Sanctions_Result && Sanctions_Result.length" type="is-danger"
                @click='isSanctionRejectedModalActive = true'>Sanction</b-button>
              <!-- <b-button type="is-success" @click='updateSanctionStatus("Approved")'>Approve</b-button> -->
              <b-button type="is-success" @click='isSanctionApprovalModalActive = true'>Approve</b-button>
            </footer>
          </div>
          <!--sanction approve modal -->
          <b-modal v-model="isSanctionApprovalModalActive" has-modal-card trap-focus :destroy-on-hide="false"
            aria-role="dialog" close-button-aria-label="Close" aria-modal>
            <div class="modal-card" style="width: 500px; height: 400px;">
              <header class="modal-card-head is-flex is-justify-content-center">
                <p class="title is-4 has-text-centered">Approve the Sanction Information!</p>
              </header>
              <section class="modal-card-body has-text-centered">
                <div class="is-flex is-justify-content-center is-align-items-center mb-4">
                  <img src="../../assets/Group 1000002633.png" alt="KYC" class="image is-128x128">
                </div>
                <p>Do you want</p>
                <p>to approve the sanction information?</p>
              </section>
              <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
                <b-button type="is-success" @click="updateSanctionStatus('APPROVED')" :loading="isButtonLoading">Approve</b-button>
                <b-button @click="isSanctionApprovalModalActive = false">Cancel</b-button>
              </footer>
            </div>
          </b-modal>
          <!--sanction reject modal -->
          <b-modal v-model="isSanctionRejectedModalActive" has-modal-card trap-focus :destroy-on-hide="false"
            aria-role="dialog" close-button-aria-label="Close" aria-modal>
            <div class="modal-card" style="width: 500px; max-height: 90vh; overflow: hidden;">
              <header class="modal-card-head is-flex is-justify-content-center">
                <p class="title is-4 has-text-centered">Reject the Sanction Information!</p>
              </header>
              <section class="modal-card-body has-text-centered">
                <div class="is-flex is-justify-content-center is-align-items-center mb-4">
                  <img src="../../assets/Group 1000002634.png" alt="KYC" class="image is-128x128">
                </div>
                <p>Do you want</p>
                <p>to reject the sanction information?</p>

                <b-field label="Reject Reason">
                  <b-input type="textarea" v-model="sanctionRejectReason"
                    placeholder="Enter the reason for rejecting the sanction" rows="3">
                  </b-input>
                </b-field>
              </section>
              <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
                <b-button type="is-danger" :loading="isButtonLoading"
                  @click="updateSanctionStatus('REJECTED', sanctionRejectReason)">Reject</b-button>
                <b-button @click="isSanctionRejectedModalActive = false">Cancel</b-button>
              </footer>
            </div>
          </b-modal>

        </b-modal>
        <!-- approve modal -->
        <b-modal v-model="approveKyc" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
          close-button-aria-label="Close" aria-modal>
          <div class="modal-card" style="width: 500px; height: 400px;">
            <header class="modal-card-head is-flex is-justify-content-center">
              <p class="title is-4 has-text-centered">Approve the KYC Information!</p>
            </header>
            <section class="modal-card-body has-text-centered">
              <div class="is-flex is-justify-content-center is-align-items-center mb-4">
                <img src="../../assets/Group 1000002633.png" alt="KYC" class="image is-128x128">
              </div>
              <p>Do you want</p>
              <p>to approve the KYC information?</p>
            </section>
            <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
              <b-button v-if="submittedData === null" type="is-success"
                @click="createRequest(KYCUser.userId, currentData)">Approve</b-button>
              <b-button v-if="submittedData !== null" type="is-success"
                @click="updateRequest(KYCUser.kycId, currentData)">Approve</b-button>
              <b-button @click="approveKyc = false">Cancel</b-button>
            </footer>
          </div>
        </b-modal>
        <!-- reject modal -->
        <b-modal v-model="rejectKyc" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog"
          close-button-aria-label="Close" aria-modal>
          <div class="modal-card" style="width: 500px; height: 400px;">
            <header class="modal-card-head is-flex is-justify-content-center">
              <p class="title is-4 has-text-centered">Reject the KYC Information!</p>
            </header>
            <section class="modal-card-body has-text-centered">
              <div class="is-flex is-justify-content-center is-align-items-center mb-4">
                <img src="../../assets/Group 1000002634.png" alt="KYC" class="image is-128x128">
              </div>
              <p>Do you want</p>
              <p>to reject the KYC information?</p>
            </section>
            <footer class="modal-card-foot" style="display: flex; justify-content: flex-end;">
              <b-button v-if="submittedData === null" type="is-danger"
                @click="createRequest(KYCUser.userId, currentData)">Reject</b-button>
              <b-button v-if="submittedData !== null" type="is-danger"
                @click="updateRequest(KYCUser.kycId, currentData)">Reject</b-button>
              <b-button @click="rejectKyc = false">Cancel</b-button>
            </footer>
          </div>
        </b-modal>
      </template>
    </b-modal>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import ChangeRequest from "@/components/ChangeRequest";
import { appSettings } from "@/appSettings";
import { TransactionExportWizard } from "@/views/Reports/Supports";
export default {
  name: 'KYC',
  components: {
    ChangeRequest
  },
  data() {
    return {
      // isSwitchChanged: false,
      isButtonLoading: false,
      isLoading: false,
      sanction_status: "",
      sanction_reject_reason: "",
      isSanctionApprovalModalActive: false,
      isSanctionRejectedModalActive: false,
      approveKyc: false,
      rejectKyc: false,
      Sanctions_Result: [], // Sanction List
      pepSanctionData: {},
      isModalActive: false,
      showSanctionResult: false,
      filterChanged: false,
      isKycModalActive: false,
      submittedContactApprove: false,
      currentData: {},
      contactApprove: false,
      searchParams: {
        data: '',
        kycStatus: '',
        fetchCount: 10,
      },
      sanctionButtonsLoading: false,
      buttonsLoading: false,
      submittedData: {},
      KYCUsers: [],
      KYCUser: {},
      selectedAppUser: {},
      tableHeaders: [],
      isKycSanctionModalActive: false,
      exportWizard: new TransactionExportWizard("App Users"),
      labelPosition: 'on-border',
      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },
    }
  },

  computed: {
    isSanctionRejected() {
        return this.KYCUser.sanction_status === 'REJECTED';
    },
    sanctionStatusLabel() {
      if (this.KYCUser.sanction_status === 'PENDING') {
        return 'Not Updated';
      } else if (this.KYCUser.sanction_status === 'APPROVED') {
        return 'Approved';
      } else {
        return 'Sanctioned';
      }
    },
    canApproveKyc() {
      // Iterate through each field in currentData
      for (let key in this.currentData) {
        // Check if any field is rejected
        if (!this.currentData[key].approved) {
          // If any field is rejected, disable the approve button
          return false;
        }
      }
      // Enable the approve button if all fields are approved
      return true;
    },
    getKycStatusClass() {
      return (status) => {
        if (status === 'APPROVED') {
          return 'is-success';
        } else if (status === 'PENDING') {
          return 'is-warning';
        } else {
          return 'is-danger';
        }
      };
    },
    collectiveApprovalStatus() {
      // Check if any of the related fields are rejected
      if (
        !this.currentData.uidType.approved ||
        !this.currentData.uid.approved ||
        !this.currentData.uidFrontImage.approved ||
        !this.currentData.uidBackImage.approved
      ) {
        return false; // If any field is rejected, set collective approval status to false
      }
      // If all fields are approved, set collective approval status to true
      return true;
    },
    collectiveAddressApprovalStatus() {
      // Check if any of the related fields are rejected
      if (
        !this.currentData.country.approved ||
        !this.currentData.city.approved ||
        !this.currentData.addressLine1.approved ||
        !this.currentData.addressLine2.approved
      ) {
        return false; // If any field is rejected, set collective approval status to false
      }
      // If all fields are approved, set collective approval status to true
      return true;
    }
  },

  watch: {
    contactApprove(newValue) {
      console.log('contactApprove changed:', newValue);
    },
    searchParams: {
      handler() {
        this.filterChanged = true
      },
      deep: true
    },
    // Watch for changes in isSwitchChanged and update the local data property
    isSwitchChanged(newValue, oldValue) {
      this.isSwitchChanged = newValue;
    },
    searchParams: {
      handler(newValue, oldValue) {
        this.filterChanged = true
      },
      deep: true
    },

  },

  methods: {
    handleApprovalClick() {
      this.isSanctionApprovalModalActive = true;
      this.isKycSanctionModalActive = false;
    },

    updateSanctionStatus(status, rejectReason) {
      const self = this;
      self.isButtonLoading = true;
      // Set the sanction status and reject reason in the component state
      self.sanction_status = status;
      self.sanction_reject_reason = rejectReason;

      // Prepare the payload for the API request
      const payload = {
        kyc_id: self.KYCUser.kycId,
        sanction_status: status,
        sanction_reject_reason: rejectReason ? rejectReason : null
      };
      console.log(payload);

      // Make the API request
      NetworkManager.apiRequest('api/kyc/update-sanction-status', payload, function (response) {
        if (response.statusCode === 200) {
          self.KYCUser.sanction_status = status;
          console.log(response.data.message);

          // Close modals
          self.isSanctionApprovalModalActive = false;
          self.isSanctionRejectedModalActive = false;
          self.isKycSanctionModalActive = false;
          self.isButtonLoading = false;
        } else {
          const params = {
            type: 'is-warning',
            message: 'Unable to update the sanction status'
          };
          self.$buefy.toast.open(params);

          self.SanctionButtonsLoading = false;
        }
      });
    },
    SanctionView(kycId) {
      const self = this

      const data = {
        kycId: kycId,
      }
      self.buttonsLoading = true

      NetworkManager.apiRequest('api/kyc/get-sanction', data, function (e) {
        if (e.statusCode === 200) {
          self.Sanctions_Result = e.data.sanctions_Result
          console.log(self.Sanctions_Result);
          self.buttonsLoading = false
          self.isKycSanctionModalActive = true
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'Unable to get the sanction data'
          }
          self.$buefy.toast.open(params)
          self.buttonsLoading = false
        }

      })
      console.log(this.isKycSanctionModalActive, this.KYCUser.kycId);
    },
    SanctionDataView() {
      const self = this
      self.isLoading = true;
      const data = {
        kycId: this.KYCUser.kycId,
      }
      self.SanctionButtonsLoading = true

      NetworkManager.apiRequest('api/kyc/get-sanction', data, function (e) {
        if (e.statusCode === 200) {
          self.Sanctions_Result = e.data.sanctions_Result         
          self.isLoading = false
          self.isKycSanctionModalActive = true
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'Unable to get the sanction data'
          }
          self.$buefy.toast.open(params)
          
          self.isLoading = false
        }

      })
      console.log(this.isKycSanctionModalActive, this.KYCUser.kycId);
      // return this.isKycSanctionModalActive
    },

    // Method to update approval status of related fields simultaneously
    updateRelatedApprovalStatus(approvalStatus) {
      // Update approval status of related fields
      this.currentData.uidType.approved = approvalStatus;
      this.currentData.uid.approved = approvalStatus;
      this.currentData.uidFrontImage.approved = approvalStatus;
      this.currentData.uidBackImage.approved = approvalStatus;
    },
    // Method to update approval status of related address fields simultaneously
    updateRelatedAddressApprovalStatus(approvalStatus) {
      // Update approval status of related address fields
      this.currentData.country.approved = approvalStatus;
      this.currentData.city.approved = approvalStatus;
      this.currentData.addressLine1.approved = approvalStatus;
      this.currentData.addressLine2.approved = approvalStatus;
    },
    cancelKYC() {
      // Clear the data or perform any necessary cleanup
      // this.clearData();

      // Close the modal
      this.isKycModalActive = false;
      //location.reload()
    },
    toggleContactApprove(currentData) {
      console.log('toggleContactApprove called. contactApprove:', this.contactApprove);
      // Update currentData.dialCode.approved and currentData.phoneNo.approved based on contactApprove
      this.$set(currentData.dialCode, 'approved', this.contactApprove);
      this.$set(currentData.phoneNo, 'approved', this.contactApprove);
    },
    //     clearData() {
    //   // Check and reset properties of submittedData if it exists
    //   this.submittedData = this.submittedData || {};
    //   this.submittedData.uidFrontImage = this.submittedData.uidFrontImage || { value: null };
    //   this.submittedData.uidBackImage = this.submittedData.uidBackImage || { value: null };
    //   this.submittedData.userImage = this.submittedData.userImage || { value: null };

    //   // Check and reset properties of currentData if it exists
    //   this.currentData = this.currentData || {};
    //   this.currentData.uidFrontImage = this.currentData.uidFrontImage || { value: null };
    //   this.currentData.uidBackImage = this.currentData.uidBackImage || { value: null };
    //   this.currentData.userImage = this.currentData.userImage || { value: null };

    //   console.log(this.submittedData);
    //   console.log(this.currentData);
    // },
    // cancelButton(){
    //   this.isKycModalActive = false
    //   this.submittedData = null
    //   this.currentData = {}
    // },

    getImageUrl: function (path) {
      console.log(appSettings.$api_url + path + "?" + new Date().getTime());
      return appSettings.$api_url + "/" + path + "?" + new Date().getTime()
    },
    formatDateTime(date) {
      const formattedDateTime = date.toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric', // Add 'second' option
      }).replace('T', ' ');

      return formattedDateTime.slice(0, -7); // Remove last 4 characters (milliseconds)
    },
    toTitleCase(str) {
      return str.replace(/(^|[^A-Za-z])([a-z])/g, function (match, pre, word) {
        var cmb = pre + word.toUpperCase();
        return cmb.replace('_', ' ')
      });
    }
    ,

    showUserImages(user) {
      console.log(user)
      this.selectedAppUser = {}
      this.selectedAppUser = user
      this.isImageModalActive = true
    },

    loadData: function () {
      const self = this
      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/kyc/get-all', self.searchParams, function (e) {
        if (e.statusCode === 200) {
          self.KYCUsers = e.data.kycList
        }

        self.hideFullScreenLoader()
      })
    },
    openModal(rowData) {
      this.KYCUser = rowData;
      this.currentData = rowData.currentData;
      this.submittedData = rowData.submittedData;
      this.sanction_status = rowData.sanction_status;

      // Set submittedContactApprove based on submittedData.dialCode.approved
      this.submittedContactApprove = rowData.submittedData && rowData.submittedData.dialCode ? rowData.submittedData.dialCode.approved : false;

      // Set contactApprove based on currentData.dialCode.approved
      this.contactApprove = rowData.currentData && rowData.currentData.dialCode ? rowData.currentData.dialCode.approved : false;
      this.isKycModalActive = true;

      console.log(this.submittedData && this.KYCUser.created_at.slice(0, 16) !== this.KYCUser.updated_at.slice(0, 16));
      console.log(this.KYCUser.created_at.slice(0, 16));
      console.log(this.KYCUser.updated_at.slice(0, 16));
    },
    createRequest(userId, kycData) {
      const self = this
      const data = {
        userId: userId,
        kycData: kycData
      }
      self.buttonsLoading = true

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/kyc/create-request', data, function (e) {
        if (e.statusCode === 200) {
          const params = {
            type: 'is-success',
            message: 'KYC request created'
          }
          self.buttonsLoading = false

          self.$buefy.toast.open(params)
          self.hideFullScreenLoader()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'KYC request create failed!'
          }
          self.$buefy.toast.open(params)
        }
        location.reload()
        self.isKycModalActive = false
      })
    },
    updateRequest(kycId, kycData) {
      const self = this
      const data = {
        kycId: kycId,
        kycData: kycData
      }
      self.buttonsLoading = true

      self.showFullScreenLoader()
      NetworkManager.apiRequest('api/kyc/update-status', data, function (e) {
        if (e.statusCode === 200) {
          const params = {
            type: 'is-success',
            message: 'KYC updated successfully'
          }
          self.buttonsLoading = false

          self.$buefy.toast.open(params)
          location.reload()
        }
        else {
          const params = {
            type: 'is-warning',
            message: 'KYC update failed!'
          }
          self.$buefy.toast.open(params)
        }

        self.hideFullScreenLoader()
        self.isKycModalActive = false
      })
    },
  },
  mounted() {
    this.loadData()
  }
}
</script>
<style>
.with-vertical-line {
  border-right: 2px solid #ccc;
  margin: 10px 0;
}

.field-and-switch {
  display: flex;
  flex-direction: column;
}
.modal-card-body {
  padding-right: 20px; /* Optional: keep or modify based on your preference */
}
.modal-card-foot {
  justify-content: flex-end;
}

.modal-card-body {
  padding-right: 20px;
}
.modal-card-body {
  overflow-x: hidden; /* Prevent horizontal scroll */
  overflow-y: auto;   /* Allow vertical scroll */
}

.table.is-fullwidth {
  table-layout: fixed; /* Ensure the table fits within the available width */
  width: 100%;         /* Make sure the table takes the full width */
  word-wrap: break-word; /* Break long words to fit within the cell */
}



</style>