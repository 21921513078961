import Layout from "@/layout";
import Auth from "@/middlewares/auth";
import Users from "@/views/User/UserList";
import Dashboard from "@/views/Dashboard/Dashboard";
import AuditDashboard from "@/views/AuditDashboard/Dashboard";
import AllCeftTransactions from "@/views/AuditerSendMoney/AllCeftTransactions";
import AuditerBillPayments from "@/views/AuditerBillPayment/AuditerBillPayments";
import AuditerEChanneling from "@/views/AuditerEChanneling/AuditerEChanneling";
import AuditerThyaga from "@/views/AuditerThyaga/AuditerThyaga";
import CreateUser from "@/views/User/UserCreate";
import EditUser from "@/views/User/UserEdit";
import RoleList from "@/views/Role/RoleList";
import RoleCreate from "@/views/Role/RoleCreate";
import RoleEdit from "@/views/Role/RoleEdit";
import ExchangeHouseList from "@/views/ExchangeHouse/ExchangeHouseList";
import ExchangeHouseCreate from "@/views/ExchangeHouse/ExchangeHouseCreate";
import ExchangeHouseEdit from "@/views/ExchangeHouse/ExchangeHouseEdit";
import ExchangeHouseBranchList from "@/views/ExchangeHouseBranch/ExchangeHouseBranchList";
import ExchangeHouseBranchCreate from "@/views/ExchangeHouseBranch/ExchangeHouseBranchCreate";
import ExchangeHouseBranchEdit from "@/views/ExchangeHouseBranch/ExchangeHouseBranchEdit";
import ExchangeHouseConfigList from "@/views/ExchangeHouseConfig/ExchangeHouseConfigList";
import IPGProviderList from "@/views/IPGProvider/IPGProviderList";
import AdvertisementList from "@/views/Advertisement/AdvertisementList";
import AdvertisementCreate from "@/views/Advertisement/AdvertisementCreate";
import AdvertisementEdit from "@/views/Advertisement/AdvertisementEdit";
import User from "@/mixins/modules/authMixins"
import BillTransactions from "@/views/Reports/BillTransactions";
import CeftTransactions from "@/views/Reports/CeftTransactions";
import ResetPassword from "@/views/User/ResetPassword";
import AppUserList from "@/views/AppUser/AppUserList";
import TrackRemittanceList from "@/views/TrackRemittance/TrackRemittanceList";
import ThyagaTransactions from "@/views/Reports/ThyagaTransactions";
import EChannellingTransactions from "@/views/Reports/EChannellingTransactions";
import IPGProviderCurrencyList from "@/views/IPGProviderCurrency/IPGProviderCurrencyList";
import BankList from "@/views/Bank/BankList";
import BankEdit from "@/views/Bank/BankEdit";
import BankCreate from "@/views/Bank/BankCreate";
import CeftSettlements from "@/views/Reports/CeftSettlements";
import CeftInstSettlements from "@/views/Reports/CeftInstSettlements";
import ActivityLogs from "@/views/Reports/ActivityLogs";
import TrackRemittanceAPIStatus from "@/views/Reports/TrackRemittanceAPIStatus";
import AppVersionList from "@/views/AppVersion/AppVersionList";
import AppVersionCreate from "@/views/AppVersion/AppVersionCreate";
import InsuranceTransactions from "@/views/Reports/InsuranceTransactions";
import KYC from "@/views/KYC/KYC";

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login'),
        hidden: true
    },
    // {
    //     path: '/#',
    //     component: Layout,
    //     meta: { type: 'menu', icon: 'view-dashboard',
    //          isActive: false},
    //     name: 'AuditDashboard',
    //     isVisible: () => {
    //         return User.hasPermission("auditor-dashboard-view") 
    //     },
    //     component: AuditDashboard,
    //     hidden: true
    // },
    {
        path: '/#',
        component: Layout,
        isVisible: () => { return (User.hasPermission('auditor-dashboard-view') || User.hasPermission('auditor-report-view')) && !User.hasSuperPermission()},
        redirect: 'AuditDashboard',
        meta: { type: 'menu', icon: 'view-dashboard',title: 'Dashboard',
             isActive: false},
        children: [
            {
                path: '/AuditDashboard',
                name: 'AuditDashboard',
                component: AuditDashboard,
                meta: { type: '', icon: 'view-dashboard',title: 'Dashboard1',
                    isActive: false, middleware: [Auth]}
            },
        ]
    },
    {
        path: '/#',
        component: Layout,
        isVisible: () => { return User.hasPermission('auditor-report-view') && !User.hasSuperPermission()},
        redirect: '',
        meta: { type: 'menu', icon: 'script-text', title: 'Reports', isActive: false},
        children: [
            {
                path: '/AllCeftTransactions',
                name: 'AllCeftTransactions',
                component: AllCeftTransactions,
                isVisible: () => {return User.hasPermission('auditor-report-view')},
                meta: { type: 'menu-item', title: 'Send Money', icon: 'cash-fast',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/AuditerBillPayments',
                name: 'AuditerBillPayments',
                component: AuditerBillPayments,
                isVisible: () => {return User.hasPermission('auditor-report-view')},
                meta: { type: 'menu-item', title: 'Bill Payments', icon: 'receipt-text',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/AuditerThyaga',
                name: 'AuditerThyaga',
                component: AuditerThyaga,
                isVisible: () => {return User.hasPermission('auditor-report-view')},
                meta: { type: 'menu-item', title: 'Thyaga', icon: 'gift',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/AuditerEChanneling',
                name: 'AuditerEChanneling',
                component: AuditerEChanneling,
                isVisible: () => {return User.hasPermission('auditor-report-view')},
                meta: { type: 'menu-item', title: 'E-Channelling', icon: 'medical-bag',
                    isActive: false, middleware: [Auth]  }
            },
        ]
    },

    {
        path: '/resetPassword',
        name: 'ResetPassword',
        component: ResetPassword,
        hidden: true
    },
    {
        path: '/',
        hidden: true,
        redirect: 'Login',
    },
    {
        path: '/#',
        component: Layout,
        isVisible: () => {return !User.hasPermission('auditor-dashboard-view') && !User.hasPermission('auditor-report-view') || User.hasSuperPermission()},
        redirect: 'Dashboard',
        meta: { type: 'menu', icon: 'view-dashboard',
            title: 'Dashboard', isActive: false},
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: { type: '', icon: 'view-dashboard', title: 'Dashboard',
                    isActive: false, middleware: [Auth]}
            }
        ]
    },
    
    // {
    //     path: '/Auditor',
    //     component: AuditDashboard,
    //     isVisible: () => {
    //         return User.hasPermission("lr-report-view") 
    //     },
    //     redirect: () => {
    //         if(User.hasPermission("lr-report-view")) return 'AuditDashboard'
    //     },
    //         children: [
    //         {
    //             path: '/AuditDashboard',
    //             name: 'AuditDashboard',
    //             component: AuditDashboard
    //         }
    //     ]
    // },
    {
        path: '/#',
        component: Layout,
        isVisible: () => {
            return User.hasPermission('user-view') ||
                User.hasPermission('role-view') ||
                User.hasPermission('bank-view') ||
                User.hasPermission('exchange-house-view') ||
                User.hasPermission('advertisement-view') ||
                User.hasPermission('app-user-view') ||
                User.hasPermission('auditor-app-user-view') ||
                User.hasPermission('ipg-provider-view') ||
                User.hasPermission('app-version-view')
        },
        redirect: '',
        meta: { type: 'menu', icon: 'cog', title: 'Administrator', isActive: false},
        children: [
            {
                path: '/users',
                name: 'Users',
                isVisible: () => {return User.hasPermission("user-view")},
                component: Users,
                meta: { type: 'menu-item', title: 'Users', icon: 'account-multiple',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/users/create',
                name: 'CreateUser',
                component: CreateUser,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/users/edit/:id',
                name: 'EditUser',
                component: EditUser,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/roles',
                name: 'Roles',
                isVisible: () => {return User.hasPermission("role-view")},
                component: RoleList,
                meta: { type: 'menu-item', title: 'Roles', icon: 'human-male-board', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/roles/create',
                name: 'CreateRole',
                component: RoleCreate,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/roles/edit/:id',
                name: 'EditRole',
                component: RoleEdit,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/banks',
                name: 'Bank',
                isVisible: () => {return User.hasPermission("bank-view")},
                component: BankList,
                meta: { type: 'menu-item', title: 'Banks', icon: 'bank', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/banks/create',
                name: 'BankCreate',
                component: BankCreate,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/banks/edit/:id',
                name: 'BankEdit',
                component: BankEdit,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/provider',
                name: 'IPGProvider',
                isVisible: () => {return User.hasPermission("ipg-provider-view")},
                component: IPGProviderList,
                meta: { type: 'menu-item', title: 'IPG Providers', icon: 'home', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/exchangeHouse',
                name: 'ExchangeHouse',
                isVisible: () => {return User.hasPermission("exchange-house-view")},
                component: ExchangeHouseList,
                meta: { type: 'menu-item', title: 'Exchange House', icon: 'office-building-marker', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/exchangeHouse/create',
                name: 'CreateExchangeHouse',
                component: ExchangeHouseCreate,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/exchangeHouse/edit/:id',
                name: 'EditExchangeHouse',
                component: ExchangeHouseEdit,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/exchangeHouseBranch/:id',
                name: 'ExchangeHouseBranch',
                component: ExchangeHouseBranchList,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/exchangeHouseBranch/create/:id',
                name: 'CreateExchangeHouseBranch',
                component: ExchangeHouseBranchCreate,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/exchangeHouseBranch/edit/:id',
                name: 'EditExchangeHouseBranch',
                component: ExchangeHouseBranchEdit,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/advertisements',
                name: 'Advertisements',
                isVisible: () => {return User.hasPermission("advertisement-view")},
                component: AdvertisementList,
                meta: { type: 'menu-item', title: 'Advertisements', icon: 'television-classic', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/advertisements/create',
                name: 'CreateAdvertisement',
                component: AdvertisementCreate,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/advertisements/edit/:id',
                name: 'EditAdvertisement',
                component: AdvertisementEdit,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/appUsers',
                name: 'AppUser',
                isVisible: () => {return User.hasPermission("app-user-view") || User.hasPermission("auditor-app-user-view")},
                component: AppUserList,
                meta: { type: 'menu-item', title: 'App Users', icon: 'account-card-outline', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/appVersions',
                name: 'AppVersion',
                isVisible: () => {return User.hasPermission("app-version-view")},
                component: AppVersionList,
                meta: { type: 'menu-item', title: 'App Versions', icon: 'cellphone-play', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/appVersions/create',
                name: 'AppVersionCreate',
                component: AppVersionCreate,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
        ]
    },
    {
        path: '/KYC',
        component: Layout,
        isVisible: () => {return User.hasPermission("kyc-view")},
        redirect: 'KYC',
        meta: { type: 'menu', icon: 'account-check',
            title: 'KYC', isActive: false},
        children: [
            {
                path: '/kyc',
                name: 'KYC',
                component: KYC,
                meta: { type: '', icon: 'account-check', title: 'KYC',
                    isActive: false, middleware: [Auth]}
            }
        ]
    },

    {
        path: '/#',
        component: Layout,
        isVisible: () => {return !User.hasSuperPermission() && User.hasPermission('exchange-house-config-view') && !User.hasPermission('exchange-house-edit')},
        redirect: 'ExchangeHouseConfig',
        meta: { type: 'menu', icon: 'store-cog', title: 'Exchange House Config', isActive: false},
        children: [
            {
                path: '/exchangeHouseConfigList/',
                name: 'ExchangeHouseConfig',
                component: ExchangeHouseConfigList,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
        ]
    },

    {
        path: '/#',
        component: Layout,
        isVisible: () => {return !User.hasSuperPermission() && User.hasPermission('ipg-currency-view')},
        redirect: 'IPGProviderCurrency1',
        meta: { type: 'menu', icon: 'store-cog', title: 'IPG Provider Currencies', isActive: false},
        children: [
            {
                path: '/ipgProviderCurrencies/',
                name: 'IPGProviderCurrency1',
                component: IPGProviderCurrencyList,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/ipgProviderCurrencies/:id',
                name: 'IPGProviderCurrency2',
                component: IPGProviderCurrencyList,
                meta: { type: '', isActive: false, middleware: [Auth]  }
            },
        ]
    },

    {
        path: '/#',
        component: Layout,
        isVisible: () => {
            return User.hasPermission("bill-payment-report-view") ||
                User.hasPermission("send-money-report-view") ||
                User.hasPermission("thyaga-report-view") ||
                User.hasPermission("echannelling-report-view") ||
                User.hasPermission("track-remit-report-view")
        },
        redirect: '',
        meta: { type: 'menu', icon: 'script-text', title: 'Reports', isActive: false},
        children: [
            {
                path: '/transaction/bill',
                name: 'BillTransaction',
                isVisible: () => {return User.hasPermission("bill-payment-report-view")},
                component: BillTransactions,
                meta: { type: 'menu-item', title: 'Bill Transactions', icon: 'receipt-text',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/transaction/ceft',
                name: 'CEFTTransaction',
                isVisible: () => {return User.hasPermission("send-money-report-view")},
                component: CeftTransactions,
                meta: { type: 'menu-item', title: 'Send Money Transactions', icon: 'cash-fast',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/transaction/thyaga',
                name: 'ThyagaTransaction',
                isVisible: () => {return User.hasPermission("thyaga-report-view")},
                component: ThyagaTransactions,
                meta: { type: 'menu-item', title: 'Thyaga Transactions', icon: 'gift',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/transaction/eChannelling',
                name: 'EChannellingTransaction',
                isVisible: () => {return User.hasPermission("echannelling-report-view")},
                component: EChannellingTransactions,
                meta: { type: 'menu-item', title: 'EChannelling Transactions', icon: 'medical-bag',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/transaction/insurance',
                name: 'InsuranceTransaction',
                isVisible: () => {return User.hasPermission("insurance-report-view")},
                component: InsuranceTransactions,
                meta: { type: 'menu-item', title: 'Insurance Transactions', icon: 'account-child',
                    isActive: false, middleware: [Auth]  }
            },
            {
                path: '/trackRemittance',
                name: 'TrackRemittance',
                isVisible: () => {return User.hasPermission("track-remit-report-view")},
                component: TrackRemittanceList,
                meta: { type: 'menu-item', title: 'Track Remittances', icon: 'text-search-variant', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/settlementReport',
                name: 'SettlementReport',
                isVisible: () => {return User.hasPermission("settlement-report-view")},
                component: CeftSettlements,
                meta: { type: 'menu-item', title: 'Scheduled Settlement Report', icon: 'briefcase-arrow-left-right', isActive: false, middleware: [Auth]  }
            },
            // {
            //     path: '/settlementReport/instant',
            //     name: 'InstSettlementReport',
            //     isVisible: () => {return User.hasPermission("settlement-report-view")},
            //     component: CeftInstSettlements,
            //     meta: { type: 'menu-item', title: 'Instant Settlement Report', icon: 'briefcase-arrow-left-right', isActive: false, middleware: [Auth]  }
            // },
            {
                path: '/trackRemittanceStatus',
                name: 'TrackRemittanceStatus',
                isVisible: () => {return User.hasPermission("track-remit-report-view")},
                component: TrackRemittanceAPIStatus,
                meta: { type: 'menu-item', title: 'Track Remittance API Status', icon: 'store-search', isActive: false, middleware: [Auth]  }
            },
            {
                path: '/activityLogs',
                name: 'ActivityLogs',
                isVisible: () => {return User.hasPermission("activity-log-report-view")},
                component: ActivityLogs,
                meta: { type: 'menu-item', title: 'Activity Log Report', icon: 'format-list-text', isActive: false, middleware: [Auth]  }
            },
        ]
    },
  // New route for System Alert
  {
    path: '',
    component: Layout,
    isVisible: () => { return User.hasSuperPermission() },
    redirect: 'http://dev-lankaremit.paymediasolutions.com:5015/',
    meta: { type: 'menu', icon: 'bell', title: 'System Alert', isActive: false },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { type: '', icon: 'bell', title: 'Dashboard', isActive: false, middleware: [Auth] }
      }
    ]
  }
]


export default routes
