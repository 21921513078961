<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="" active >ExchangeHouseConfig - {{ exchange_house_name }}</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 70vh">

      <section v-if="hasPermission('exchange-house-config-create')">
        <b-field grouped>
          <template slot="label">Currency </template>
          <b-select v-model="selectedCurrencyCode" placeholder="Select a Currency" :disabled="!editEnabled">
            <option :value="''"  disabled selected>Select Currency</option>
            <option v-for="currency in currencyListRef" :value="currency.code" :key="currencyList.code">{{ currency.name + " (" + currency.symbol_native + ")" }}</option>
          </b-select>
          <p class="control">
            <b-button class="button is-success" @click="addCurrency" :disabled="selectedCurrencyCode === '' || !editEnabled">Add Currency</b-button>
          </p>
        </b-field>
      </section>

      <section style="margin-top: 20px">
        <div class="card">
          <div class="card-content">
            <div style="margin-bottom: 20px">Added Currencies</div>
            <div v-for="config in configurationListRef">
              <div class="columns">
                <div class="column is-one-fifth">
                  <b-field>
                    <b-tag type="is-primary is-light" size="is-medium">{{config.currency_code}}</b-tag>
                  </b-field>
                </div>
                <div class="column">
                  <currency-input
                      label="Rate"
                      v-model="config.exchange_rate.value"
                      :max-length="10"
                      :decimals="4"
                      :label-position="labelPosition"
                      :type="config.exchange_rate.class"
                      :message="config.exchange_rate.message"
                      :disabled="!editEnabled"
                      @on-blur="config.exchange_rate.blur()"
                  ></currency-input>
                </div>
                <div class="column">
                  <currency-input
                      label="Service Charge"
                      v-model="config.service_charge.value"
                      :max-length="10"
                      :decimals="4"
                      :label-position="labelPosition"
                      :type="config.service_charge.class"
                      :message="config.service_charge.message"
                      :disabled="!editEnabled"
                      @on-blur="config.service_charge.blur()"
                  ></currency-input>
                </div>
                <div class="column is-one-fifth">
                  <b-button class="button is-danger" @click="deleteCurrency(config)" :disabled="!editEnabled">Remove</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
        </div>
        <div class="column">
          <div v-if="hasPermission('exchange-house-config-edit')" class="buttons is-pulled-right" style="margin: 5px">
            <b-button v-if="editEnabled" @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
            <b-button v-if="!editEnabled" @click="editEnabled = true"  type="is-danger">Enable Edit</b-button>
            <b-button v-if="editEnabled" @click="editEnabled = false"  type="is-danger">Disable Edit</b-button>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import ChangeRequest from "@/components/ChangeRequest"
import NetworkManager from "@/network"
import CurrencyInput from "@/components/CurrencyInput"
import {ExchangeHouseConfigData} from "@/views/ExchangeHouseConfig/Support"

export default {
  name: 'ExchangeHouseConfigList',
  components: {
    CurrencyInput,
    ChangeRequest
  },
  data() {
    return {
      labelPosition: 'on-border',
      exchangeHouseID: 0,
      selectedCurrencyCode: '',
      editEnabled: false,

      minValue: 0.0001,
      maxValue: 9999999999,

      exhouses: [],
      exchange_house_id: 0,
      exchange_house_name: '',
      currencyList: [],
      configurationListOriginal: [],
      configurationList: [],

      testVal: new ExchangeHouseConfigData()
    }
  },

  computed: {
    currencyListRef(){
      const self = this

      let list = []
      self.currencyList.forEach(function (item){
        if(!self.hasCurrencyInConfig(item.code)){
          list.push(item)
        }
      })

      self.selectedCurrencyCode = ''

      return list
    },

    configurationListRef(){
      const self = this

      let list = []
      self.configurationList.forEach(function (item){
        if(item.action !== "delete"){
          list.push(item)
        }
      })

      return list
    }
  },

  watch: {
    exchange_house_id(newVal, oldVal){
      if(newVal > 0){
        this.loadData()
      }
    },

    editEnabled(newVal, oldVal){
      if(!newVal) {
        this.selectedCurrencyCode = ''
        this.loadData()
      }
    },

    testVal(newVal, oldVal){
      console.log(newVal)
    }
  },

  methods: {
    onNumberInput: function (value, e){
      const self = this

      const numRegex = /\d|Backspace/
      console.log(e.key)
      console.log(value)
      if(!numRegex.test(e.key)){
        e.preventDefault()
      }

      if(value.length >= 10){

      }
    },

    hasCurrencyInConfig: function (code){
      const self = this
      let hasCode = false

      for(let i = 0; i < self.configurationList.length; i++){
        let config = self.configurationList[i]

        if(config.currency_code === code){
          hasCode = true
          break
        }
      }

      return hasCode
    },

    addCurrency: function (){
      const self = this
      let config = new ExchangeHouseConfigData()
      config.exchange_rate.value = 0.0
      config.service_charge.value = 0.0
      config.currency_code = self.selectedCurrencyCode

      self.configurationList.push(config)
    },

    deleteCurrency: function (selectedConfig){
      const self = this
      let hasCode = false

      for(let i = 0; i < self.configurationListOriginal.length; i++){
        let config = self.configurationListOriginal[i]

        if(config.currency_code === selectedConfig.currency_code){
          hasCode = true
          selectedConfig.action = "delete"
          break
        }
      }

      if(!hasCode){
        for(let i = 0; i < self.configurationList.length; i++){
          let config = self.configurationList[i]

          if(config.currency_code === selectedConfig.currency_code){
            self.configurationList.splice(i, 1)
            break
          }
        }
      }

      console.log(self.configurationList)
    },

    initialData: function (){
      const  self = this

      NetworkManager.apiRequest('api/exchangeHouseConfig/initial-data', {}, function (e){
        if(e.statusCode === 200){
          self.currencyList = e.data.currencies
        }
      })
    },

    loadExhouses: function (){
      const  self = this

      NetworkManager.apiRequest('api/exchangeHouse/list', {}, function (e){
        if(e.statusCode === 200){
          self.exhouses = e.data.exhouses


          if(!self.isSuperAdmin() && !self.hasPermission('exchange-house-edit')){
            let exHouseCode = self.authUser().branch

            self.exhouses.forEach(function (item){
              if(item.code === exHouseCode){
                self.exchange_house_id = item.id
                self.exchange_house_name = item.name
              }
            })

            if(self.exchange_house_id === 0){
              const params = {
                type: 'is-warning',
                message: 'You do not have permission to view exchangehouse configurations'
              }
              self.$buefy.toast.open(params)
            }
          }
          else {
            self.exchange_house_id = self.$route.params.id

            self.exhouses.forEach(function (item){
              if(item.id === self.exchange_house_id){
                self.exchange_house_name = item.name
              }
            })
          }
        }
      })
    },

    loadData: function (){
      const  self = this

      self.configurationList = []
      self.configurationListOriginal = []

      const data = {
        exchange_house_id: self.exchange_house_id
      }

      NetworkManager.apiRequest('api/exchangeHouseConfig/list', data, function (e){
        if(e.statusCode === 200){
          e.data.configurations.forEach(function (item){
            let config = new ExchangeHouseConfigData()
            config.currency_code = item.currency_code
            config.exchange_rate.value = item.exchange_rate
            config.service_charge.value = item.service_charge

            self.configurationList.push(config)
          })

          self.configurationListOriginal = e.data.configurations
        }
      })
    },

    validate: function (){
      const self = this
      let isValid = true

      self.configurationList.forEach(function (item){
        isValid = item.exchange_rate.validate()
        isValid = item.service_charge.validate()

        if(isValid){
          if((item.exchange_rate.value < self.minValue || item.exchange_rate.value > self.maxValue)){
            isValid = false
            item.exchange_rate.invalidate('value must be greater than ' + self.minValue + ' and less than ' + self.maxValue)
          }

          if((item.service_charge.value < 0 || item.service_charge.value > self.maxValue)){
            isValid = false
            item.exchange_rate.invalidate('value must be greater than 0.0 and less than ' + self.maxValue)
          }
        }

      })

      return isValid
    },

    validateAndSubmit: function (){
      const  self = this

      if(self.validate()){
        let list = []

        self.configurationList.forEach(function (item){
          let data = {
            action: item.action,
            currency_code: item.currency_code,
            exchange_rate: item.exchange_rate.value,
            service_charge: item.service_charge.value
          }

          list.push(data)
        })

        let data = {
          exchange_house_id: self.exchange_house_id,
          configurations: list
        }

        NetworkManager.apiRequest('api/exchangeHouseConfig/save', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'Exchange House Configuration Saved'
            }
            self.$buefy.toast.open(params)
            self.editEnabled = false
          }
          else {
            const params = {
              type: 'is-warning',
              message: 'Exchange House Configuration Save Failed!.'
            }
            self.$buefy.toast.open(params)
          }
        })
      }

    },

  },

  mounted() {
    const self = this

    self.initialData()
    self.loadExhouses()
  }
};
</script>

<style>
.is-sticky-column-one {
  background: #23d160 !important;
  color: white !important;
}
.is-sticky-column-two {
  background: #167df0 !important;
  color: white !important;
}
</style>
