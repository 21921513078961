<template>
  <section>

    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="" active >Track Remittance API Status</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
          
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 83vh">
      
      <b-table
          :height="vhToPixels(70)"
          :data="apiStatusData"
          :paginated="tableOptions.isPaginated"
          :per-page="tableOptions.perPage"
          :current-page.sync="tableOptions.currentPage"
          :pagination-simple="tableOptions.isPaginationSimple"
          :pagination-position="tableOptions.paginationPosition"
          :default-sort-direction="tableOptions.defaultSortDirection"
          :pagination-rounded="tableOptions.isPaginationRounded"
          :sort-icon="tableOptions.sortIcon"
          :sort-icon-size="tableOptions.sortIconSize"
          default-sort="id"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :sticky-header="tableOptions.stickyHeaders"
          :page-input="tableOptions.hasInput"
          :pagination-order="tableOptions.paginationOrder"
          :page-input-position="tableOptions.inputPosition"
          :debounce-page-input="tableOptions.inputDebounce">

        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="organization" label="Organization" sortable v-slot="props" width="200">
          {{ props.row.organization }}
        </b-table-column>

        <b-table-column field="status" label="Status" sortable v-slot="props" width="200">
          <span :class="'tag ' + (props.row.status === 'WORKING' ? 'is-success' : 'is-warning')">
            {{ props.row.status }}
          </span>
        </b-table-column>

        <b-table-column field="created_at" label="Created At" sortable centered v-slot="props">
                <span class="tag is-success expanded-col">
                    {{ new Date(props.row.created_at).toLocaleString() }}
                </span>
        </b-table-column>

        <b-table-column field="updated_at" label="Updated At" sortable centered v-slot="props">
                <span class="tag is-success expanded-col">
                    {{ new Date(props.row.updated_at).toLocaleString() }}
                </span>
        </b-table-column>
      </b-table>
    </div>
    <br />
  </section>
</template>

<script>
import NetworkManager from "@/network"
import ChangeRequest from "@/components/ChangeRequest";

export default {
  name: 'TrackRemittanceAPIStatus',
  components: {
    ChangeRequest
  },
  data() {
    return {
      apiStatusData : [],

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 50,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
      },
    }
  },

  computed: {
  },

  watch: {

  },

  methods: {

    loadData: function (){
      const  self = this
      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/trackRemittance/status', {}, function (e){
        if(e.statusCode === 200){
          self.apiStatusData = e.data.list
        }

        self.hideFullScreenLoader()
      })
    },

  },

  mounted() {
    this.loadData()
  }
}
</script>


