<template>
  <div class="card" style="margin: 10px; height: 350px">
    <div class="card-content">
      <vue-apex-charts ref="pieChart" type="pie" height="300" width="550" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

export default {
  name: "AppUsersPieChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {

      series: [],
      chartOptions: {
        chart: {
          height: 400,
          type: 'pie',
        },
        title: {
          text: 'App Users',
          align: 'left'
        },
        labels: [],
        // responsive: [{
        //   breakpoint: 480,
        //   options: {
        //     chart: {
        //       height: 400
        //     },
        //     legend: {
        //       position: 'bottom'
        //     }
        //   }
        // }]
      },
    }
  },

  methods: {
    loadData: function (){
      const self = this

      NetworkManager.apiRequest('api/dashboard/getAppUsersByCountry', {}, function (e){
        if(e.statusCode === 200){
          self.series = e.data.counts
          self.$refs.pieChart.updateOptions({
            labels: e.data.countries
          })
        }
        else {
          //
        }
      })
    }
  },

  mounted() {
    this.loadData()
  }

}
</script>

<style scoped>

</style>
