<template>
  <section>

    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb align="is-left">
            <b-breadcrumb-item tag='router-link' to="" active>Track Remittances</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>
    <div class="card-content" style="overflow: auto; height: 83vh">
      <div class="columns mt-2">

        <b-field class="column is-one-fifths is-small" label="From" label-position="on-border">
          <b-datetimepicker v-model="fromDate" @input="onFromDateChange()" size="is-small" :show-week-number="false" locale="en-CA" placeholder="Click to select..."
            icon="calendar-today" trap-focus>
          </b-datetimepicker>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="To" label-position="on-border">
          <b-datetimepicker v-model="toDate" size="is-small" :min-date="fromDate" :show-week-number="false" locale="en-CA" placeholder="Click to select..." icon="calendar-today"
            trap-focus>
          </b-datetimepicker>
        </b-field>
        <b-field class="column is-one-fifths is-small" label="Country" label-position="on-border">
          <b-select v-model="countryCode" placeholder="Select a Country" size="is-small" expanded>
            <option :value="''" selected>All Countries</option>
            <option v-for="country in countryList" :value="country.code" :key="country.code">{{ country.name }}</option>
          </b-select>
        </b-field>

        <b-field class="column is-one-fifths is-small" label="UID" label-position="on-border">
          <b-input v-model="uid" @blur="uid = uid.trim()" maxlength="30" size="is-small"></b-input>
        </b-field>
        <!-- <b-field class="column is-one-fifths is-small" label="Tracking No" label-position="on-border">
          <b-input v-model="tracking_no" @blur="tracking_no = tracking_no.trim()" maxlength="30" size="is-small"></b-input>
        </b-field> -->
        <b-field class="column is-one-fifths is-small" label="Fetch Count" label-position="on-border">
          <b-select v-model="fetchCount" placeholder="Select a Count" size="is-small" expanded>
            <option :value="10" selected>10</option>
            <option :value="25">25</option>
            <option :value="50">50</option>
            <option :value="100">100</option>
            <option :value="99999999">All</option>
          </b-select>
        </b-field>
        <!-- <b-field class="column" label="">
          <div class="buttons">
            <b-button size="is-small" type="is-success" @click="searchData">Search</b-button>
            
            <b-tooltip label="Clear All Filters" style="margin-right: 5px">
              <b-button size="is-small" :disabled="!searched || filterChanged" @click="clearFilters" type="is-danger" icon-right="close"></b-button>
            </b-tooltip>
            <b-tooltip label="Download as Excel">
              <b-button size="is-small" :disabled="filterChanged" @click="generateXlsx" type="is-info" icon-right="microsoft-excel"></b-button>
            </b-tooltip>
            <b-tooltip label="Download as PDF" style="margin-left: 5px">
              <b-button size="is-small" :disabled="filterChanged" @click="generatePdf" type="is-info" icon-right="file-pdf-box"></b-button>
            </b-tooltip>
          </div>
        </b-field> -->
      </div>
      <div class="columns" style="margin-top: -25px;">
        <b-field class="column is-one-fifths is-small" label="Tracking No" label-position="on-border">
          <b-input v-model="tracking_no" @blur="tracking_no = tracking_no.trim()" maxlength="30" size="is-small"></b-input>
        </b-field>
       

        <b-field class="column is-four-fifths " >
          <div class="buttons">
            <b-button size="is-small" type="is-success" @click="searchData">Search</b-button>

            <b-tooltip label="Clear All Filters" style="margin-right: 5px">
              <b-button size="is-small" :disabled="!searched || filterChanged"  @click="clearFilters" type="is-danger"
                icon-right="close"></b-button>
            </b-tooltip>

            <b-tooltip label="Download as Excel">
              <b-button size="is-small" :disabled="filterChanged" @click="generateXlsx" type="is-info" icon-right="microsoft-excel"></b-button>
            </b-tooltip>
            <b-tooltip label="Download as PDF" style="margin-left: 5px">
              <b-button size="is-small" :disabled="filterChanged" @click="generatePdf" type="is-info" icon-right="file-pdf-box"></b-button>
            </b-tooltip>
          </div>
        </b-field>
      </div>

      <b-table :height="vhToPixels(55)" 
      style="margin-top: -20px!important;"
      :data="trackRemitHistory" :paginated="tableOptions.isPaginated" :per-page="tableOptions.perPage"
        :current-page.sync="tableOptions.currentPage" :pagination-simple="tableOptions.isPaginationSimple" :pagination-position="tableOptions.paginationPosition"
        :default-sort-direction="tableOptions.defaultSortDirection" :pagination-rounded="tableOptions.isPaginationRounded" :sort-icon="tableOptions.sortIcon"
        :sort-icon-size="tableOptions.sortIconSize" default-sort="id" aria-next-label="Next page" aria-previous-label="Previous page" aria-page-label="Page"
        aria-current-label="Current page" :sticky-header="tableOptions.stickyHeaders" :page-input="tableOptions.hasInput" :pagination-order="tableOptions.paginationOrder"
        :page-input-position="tableOptions.inputPosition" :debounce-page-input="tableOptions.inputDebounce">

        <b-table-column field="id" label="ID" width="40" sortable numeric v-slot="props" sticky>
          {{ props.row.id }}
        </b-table-column>

        <b-table-column field="uid" label="UID" sortable v-slot="props" width="200">
          {{ props.row.uid }}
        </b-table-column>

        <b-table-column field="tracking_no" label="Tracking Number" sortable v-slot="props" width="200">
          {{ props.row.tracking_no }}
        </b-table-column>

        <b-table-column field="organization" label="Organization" sortable v-slot="props" width="200">
          {{ props.row.organization }}
        </b-table-column>

        <b-table-column field="status" label="Status Code" sortable v-slot="props" width="200">
          {{ props.row.status }}
        </b-table-column>

        <b-table-column field="description" label="Description" sortable v-slot="props" width="200">
          {{ props.row.description }}
        </b-table-column>

        <b-table-column field="created_at" label="Search Date" sortable centered v-slot="props">
          <span class="tag is-success">
            {{ new Date(props.row.created_at).toLocaleString() }}
          </span>
        </b-table-column>
      </b-table>
    </div>
    <br />
  </section>
</template>

<script>
import NetworkManager from "@/network"
import ChangeRequest from "@/components/ChangeRequest";
import { TransactionExportWizard } from "@/views/Reports/Supports";

export default {
  name: 'TrackRemittance',
  components: {
    ChangeRequest
  },
  data() {
    return {
      uid: '',
      fromDate: new Date(),
      toDate: new Date(),
      tracking_no: '',
      filterChanged: false,
      fetchCount: 10,
      trackRemitHistory: [],
      filteredList: [],
      tableHeaders: [],
      countryList: [],
      searched: false,
      countryCode: '',
      exportWizard: new TransactionExportWizard("Track Remittances"),

      tableOptions: {
        isPaginated: true,
        isPaginationSimple: false,
        isPaginationRounded: false,
        paginationPosition: 'bottom',
        defaultSortDirection: 'asc',
        sortIcon: 'arrow-up',
        sortIconSize: 'is-small',
        currentPage: 1,
        perPage: 10,
        hasInput: false,
        paginationOrder: '',
        inputPosition: '',
        inputDebounce: '',
        stickyHeaders: true,
        
      },
    }
  },

  computed: {
  },

  watch: {
    fromDate(newVal, oldVal) {
      this.filterChanged = true
    },

    toDate(newVal, oldVal) {
      this.filterChanged = true
    }
  },

  methods: {

    searchData: function () {
    this.searched = true,
    this.loadData()  
    },

    clearFilters: function () {
      const self = this

      self.fromDate = new Date();
      self.fromDate.setHours(0, 0, 1, 0);

      self.toDate = new Date();
      self.toDate.setHours(23, 59, 59, 999);

      self.fetchCount = 10,
      self.filterChanged = false;
      self.searched = false;
      self.uid = '';
      self.tracking_no = '';
      self.loadData();
    },


    generateXlsx: function () {
      const self = this

      self.exportWizard.exportToXlsx(
        new Date(),
        self.authUser().username,
        self.fromDate,
        self.toDate,
        "trackRemittances_" + self.formatDateTime(new Date()),
        self.tableHeaders,
        self.filteredList
      )
    },

    generatePdf: function () {
      const self = this

      self.exportWizard.exportToPDF(
        new Date(),
        self.authUser().username,
        self.fromDate,
        self.toDate,
        "trackRemittances_" + self.formatDateTime(new Date()),
        self.tableHeaders,
        self.filteredList
      )
    },

    onFromDateChange: function () {
      const self = this

      if (self.toDate.getTime() < self.fromDate.getTime()) {
        self.toDate = self.fromDate
      }
    },

    loadData: function () {
      const self = this
      self.showFullScreenLoader()

      let data = {
        from_date: self.formatDateTime(self.fromDate),
        to_date: self.formatDateTime(self.toDate),
        uid: self.uid,
        fetchCount: self.fetchCount,
        tracking_no: self.tracking_no
        
      }

      NetworkManager.apiRequest('api/trackRemittance/history', data, function (e) {
        if (e.statusCode === 200) {
          self.trackRemitHistory = e.data.list
          self.filteredList = e.data.filteredList
          self.tableHeaders = e.data.fields
          self.filterChanged = false
          self.countryList = e.data.countries
        }

        self.hideFullScreenLoader()
      })
    },

  },

  mounted() {
    this.loadData()

    let fromDate = new Date()
    fromDate.setHours(0)
    fromDate.setMinutes(0)
    fromDate.setSeconds(0)

    let toDate = new Date()
    toDate.setHours(23)
    toDate.setMinutes(59)
    toDate.setSeconds(59)

    this.fromDate = fromDate
    this.toDate = toDate
  }
}
</script>
