import NetworkManager from "@/network"

const DocumentDownloadLogUpdate  = {

    documentNameSender : function (fileName) {
        let data = {
            file_name: fileName, 
          }
  
          NetworkManager.apiRequest('api/Report/add-download-log', data, function (e) {
            
          })
    }
}

export default DocumentDownloadLogUpdate;