<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' to="/exchangeHouse" >Exchange Houses</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Edit</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 70vh">
      <section>
        <b-field
            horizontal
            :type="exHouseData.name.class"
            :message="exHouseData.name.message">
          <template slot="label">Name <span class="has-text-danger">*</span></template>
          <b-input @blur="exHouseData.name.blur()" v-model="exHouseData.name.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseData.code.class"
            :message="exHouseData.code.message">
          <template slot="label">Exchange House Code <span class="has-text-danger">*</span></template>
          <b-input @blur="exHouseData.code.blur()" v-model="exHouseData.code.value" maxlength="100" expanded readonly></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseData.website.class"
            :message="exHouseData.website.message">
          <template slot="label">Website <span class="has-text-danger">*</span></template>
          <b-input @input="exHouseData.website.blur()" v-model="exHouseData.website.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseData.address.class"
            :message="exHouseData.address.message">
          <template slot="label">Address <span class="has-text-danger">*</span></template>
          <b-input type="textarea" @blur="exHouseData.address.blur()" v-model="exHouseData.address.value" maxlength="200"></b-input>
        </b-field>

        <b-field
             horizontal
             :type="exHouseData.country_code.class"
             :message="exHouseData.country_code.message">
          <template slot="label">Country <span class="has-text-danger">*</span></template>
          <b-select @blur="exHouseData.country_code.blur()" v-model="exHouseData.country_code.value" placeholder="Select a Country" >
            <option :value="''"  disabled selected>Select a Country</option>
            <option v-for="country in countryList" :value="country.code" :key="country.code">{{ country.name }}</option>
          </b-select>
        </b-field>

        <b-field horizontal>
          <div class="block"></div>
        </b-field>

        <b-field horizontal
                 :type="exHouseData.image.class"
                 :message="exHouseData.image.message" >
          <template slot="label">Image <span class="has-text-danger">*</span></template>
          <div>
            <label>(Uploading image size should be less than {{ imageValidations.maxSize }}KB)</label>
            <image-upload-component
                :type="exHouseData.image.class"
                v-model="exHouseData.image.value"
                :index="0"
                :image-height="imageHeight"
                :image-width="imageWidth"
                :image-validations="imageValidations"
                :placeholder_image="placeholder_image"
                @on-upload="onImageUpload"
            ></image-upload-component>
          </div>
        </b-field>

        <b-field horizontal>
          <div class="block"></div>
        </b-field>

        <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="exHouseData.disabled.value"

                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="exHouseData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger">
              Not Active
            </b-radio>

          </div>
        </b-field>

      </section>

      <canvas ref="exhouseImageCanvas" style="display: none"></canvas>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column has-text-danger is-italic">Fields marked with <big>*</big> are mandatory</p>
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
            <b-button @click="navigateTo('ExchangeHouse')"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import { FieldValidator } from "@/helpers/validators/fieldValidator"
import {appSettings} from "@/appSettings"
import imageUploadComponent, {ImageValidator} from "@/components/ImageUploadComponent"

export default {
  name: "EditExchangeHouse",
  components: {imageUploadComponent},
  data() {
    return {
      countryList: [],

      imageHeight: 200,
      imageWidth : 300,
      marginError: 15,
      image: null,
      imageValidations: new ImageValidator(0,0,0,0,100),
      placeholder_image: require('@/assets/image-placeholder.png'),

      exHouseId: 0,

      exHouseData: {
        name: new FieldValidator(
            /^([A-Za-z\d]+\s?[A-Za-z\d]*){3,}$/,
            "Name can only have alphanumeric characters and should be at least 3 characters long"
        ),
        code: new FieldValidator(
            /^\w{3,}$/,
            "Exchange House Code can only have alphanumeric characters with '_' and should be at least 3 characters long"
        ),
        website: new FieldValidator(
            /^((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z\d.-]+(:\d+)?|(?:www.|[-;:&=+$,\w]+@)[A-Za-z\d.-]+)((?:\/[+~%\/.\w-_]*)?\??#?)?)$/,
            "Please enter a valid Url"
        ),
        address: new FieldValidator(
          "",
            "Please enter a valid address"
        ),
        country_code: new FieldValidator(
            /^[A-Za-z]+$/,
            "Please select a country"
        ),
        image: new FieldValidator(
            /[\w\/\\]*/,
            "Please upload an image"
        ),
        disabled: new FieldValidator()
      },

      checkBoxVal: false
    }
  },

  methods: {

    onImageUpload: function (data){
      this.exHouseData.image.blur()
      if(!data.valid){
        this.exHouseData.image.invalidate('Uploaded image is invalid')
      }
    },

    loadImage: function (path){
      return appSettings.$api_url + "/" + path+ "?" + new Date().getTime()
    },

    initialData: function (){
      const self = this
      NetworkManager.apiRequest('api/ExchangeHouse/initial-create-data', {}, function (e){
        if(e.statusCode === 200){
          self.countryList = e.data.countries

          self.getForEdit()
        }
      })
    },

    getForEdit: function (){
      const self = this

      const data = {
        id : self.$route.params.id
      }

      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/ExchangeHouse/get-for-edit', data, function (e){
        if(e.statusCode === 200){
          const exhouse = e.data.exchangeHouse
          self.exHouseId = exhouse.id
          self.exHouseData.name.value = exhouse.name
          self.exHouseData.code.value = exhouse.code
          self.exHouseData.website.value = exhouse.website
          self.exHouseData.country_code.value = exhouse.country_code
          self.exHouseData.address.value = exhouse.address
          self.exHouseData.image.value = exhouse.image
          self.exHouseData.disabled.value = exhouse.disabled

          self.placeholder_image = self.loadImage(exhouse.image)
        }

        self.hideFullScreenLoader()
      })
    },

    validate: function (){
      const self = this
      let isValid = true

      Object.keys(self.exHouseData).forEach(function(key, index) {
        console.log(self.exHouseData[key].validate())
         if(!self.exHouseData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function (){
      const self = this

      if(self.validate()){
        const data = {
          id: self.exHouseId,
          name : self.exHouseData.name.value,
          code : self.exHouseData.code.value,
          website : self.exHouseData.website.value,
          address : self.exHouseData.address.value,
          country_code : self.exHouseData.country_code.value,
          image : self.exHouseData.image.value,
          disabled : self.exHouseData.disabled.value
        }

        self.showFullScreenLoader()

        NetworkManager.apiRequest('api/ExchangeHouse/edit', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'Exchange House sent for approval'
            }
            self.$buefy.toast.open(params)
            self.navigateTo('ExchangeHouse')
          }
          else {
            const params = {
              type: 'is-warning',
              message: 'Exchange House save failed!.'
            }
            self.$buefy.toast.open(params)
          }

          self.hideFullScreenLoader()
        })
      }
    }
  },

  mounted() {
    this.initialData()
  }
}
</script>

<style scoped>
  .product-image-input {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;
    margin-bottom: 0.35rem;
  }

  label.product-image-preview {
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    cursor: pointer;
    display: block;
    width: 100%;
  }

  .product-image-edit-or-delete-bg {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #f9f9f97d;
    align-items: center;
    justify-content: center;
  }

  .product-image-input:hover .product-image-edit-or-delete-bg {
    display: flex;
  }

  .product-image-edit-or-delete {
    display: flex;
    flex-direction: row;
  }

  .form-preview .label {
    color: #bd9ea5;
  }

  img#image-to-be-cropped {
    max-height: calc(100vh - 150px);
  }
</style>
