<template>
  <div class="card" style="margin: 10px; min-height: 400px">
    <div class="card-content">
      <div class="columns">
        <div
          class="column is-three-fifths"
          style="font-weight: bold; margin-left: 10px"
        >
          <p class="title is-5">App Users</p>
        </div>
        <div class="column">
          <b-field>
            <b-datepicker class="is-pulled-right" @input="loadData" :disabled="isTableLoading" v-model="dateRange" :mobile-native="false" :max-date="new Date()" range>
              <template v-slot:trigger>
                <b-button icon-left="calendar-month-outline" size="is-small" />
              </template>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-dropdown
            @change="loadData"
            :disabled="isTableLoading"
            class="is-pulled-right"
            v-model="selectedFilter"
            aria-role="list"
          >
            <template #trigger>
              <b-button
                type="is-primary"
                size="is-small"
                icon-right="menu-down"
              >
                {{ selectedFilter.text }}
              </b-button>
            </template>

            <b-dropdown-item
              v-for="item in filters"
              :value="item"
              aria-role="listitem"
            >
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div v-if="selectedFilter.value === 0" class="columns">
        <div class="column is-three-fifths"></div>
        <div class="column">
          <b-field>
            <b-input :value="`${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`" disabled></b-input>
        </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <b-field>
            <b-input
              v-model="globalSearch"
              icon="magnify"
              placeholder="Search"
            ></b-input>
          </b-field>
        </div>
      </div>

      <div class="card-content">
        <b-table
          :height="vhToPixels(40)"
          :loading="isTableLoading"
          :data="filteredTopUsers"
          :paginated="isPaginated"
          :per-page="perPage"
          :current-page.sync="currentPage"
          :pagination-simple="isPaginationSimple"
          :pagination-position="paginationPosition"
          :default-sort-direction="defaultSortDirection"
          :pagination-rounded="isPaginationRounded"
          :sort-icon="sortIcon"
          :sort-icon-size="sortIconSize"
          default-sort="user.first_name"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          :page-input="hasInput"
          :pagination-order="paginationOrder"
          :page-input-position="inputPosition"
          :debounce-page-input="inputDebounce"
        >
          <b-table-column
            field="countries"
            label="Countries"
            sortable
            v-slot="props"
          >
            {{ props.row.country }}
          </b-table-column>

          <b-table-column
            field="counts"
            label="Counts"
            sortable
            align="right"
            v-slot="props"
            numeric
          >
            {{ props.row.count }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import NetworkManager from "@/network";

const today = new Date();

export default {
  name: "AllTransactionsTable",
  data() {
    return {
      dateRange: [today, today],
      selectedFilter: { value: 7, text: "This Week" },
      filters: [
        { value: 7, text: "This Week" },
        { value: 14, text: "Last Two Weeks" },
        { value: 30, text: "Last Month" },
        { value: 90, text: "Last Three Months" },
        { value: 365, text: "Last Year" },
        { value: 1, text: "All" },
      ],
      globalSearch: "",
      isTableLoading: false,
      TopUsers: [],
      isPaginated: true,
      isPaginationSimple: false,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 5,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
    };
  },
  // computed: {
  //   filteredElements() {
  //     // Filter elements based on the global search query
  //     return this.elements.filter(element =>
  //       element.name.toLowerCase().startsWith(this.globalSearch.toLowerCase())
  //     );
  //   },
  // },
  watch: {
    dateRange(newVal, oldVal) {
      this.selectedFilter = { value: 0, text: "Custom" };
    },
  },

  methods: {
    formatDate: function (date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    loadData: function () {
      const self = this;

      self.isTableLoading = true;

      setTimeout(() => {
        const data = {
          range: self.selectedFilter.value,
          fromDate: self.formatDate(self.dateRange[0]),
          toDate: self.formatDate(self.dateRange[1]),
        };

        NetworkManager.apiRequest("api/dashboard/TopUsers", data, function (e) {
          if (e.statusCode === 200) {
            self.TopUsers = e.data;
          } else {
            //
          }
          self.isTableLoading = false;
        });
      }, 200);
    },
  },

  computed: {
    filteredTopUsers() {
      return this.TopUsers.filter((user) => {
        return (
          user.country
            .toLowerCase()
            .startsWith(this.globalSearch.toLowerCase()) ||
          user.count
            .toString()
            .toLowerCase()
            .startsWith(this.globalSearch.toLowerCase())
        );
      });
    },
  },

  mounted() {
    this.loadData();
  },
};
</script>

<style scoped></style>
