<template>
  <section>
    <div class="card-header p-3">
      <div class="columns" style="width: 100%">
        <div class="column">
          <b-breadcrumb
              align="is-left"
          >
            <b-breadcrumb-item tag='router-link' :to="'/exchangeHouseBranch/' + $route.params.id" >ExchangeHouseBranches</b-breadcrumb-item>
            <b-breadcrumb-item tag='router-link' to="" active >Edit</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
        <div class="column">
        </div>
      </div>
    </div>

    <div class="card-content" style="overflow: auto; height: 70vh">
      <section>
        <b-field
            horizontal
            :type="exHouseBranchData.name.class"
            :message="exHouseBranchData.name.message">
          <template slot="label">Name <span class="has-text-danger">*</span></template>
          <b-input @blur="exHouseBranchData.name.blur()" v-model="exHouseBranchData.name.value" maxlength="100" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseBranchData.contact.class"
            :message="exHouseBranchData.contact.message">
          <template slot="label">Contact Number <span class="has-text-danger">*</span></template>
          <b-input @blur="exHouseBranchData.contact.blur()" v-model="exHouseBranchData.contact.value" maxlength="32" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseBranchData.website.class"
            :message="exHouseBranchData.website.message">
          <template slot="label">Website <span class="has-text-danger">*</span></template>
          <b-input @input="exHouseBranchData.website.blur()" v-model="exHouseBranchData.website.value" maxlength="200" expanded></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseBranchData.address.class"
            :message="exHouseBranchData.address.message">
          <template slot="label">Address <span class="has-text-danger">*</span></template>
          <b-input type="textarea" @blur="exHouseBranchData.address.blur()" v-model="exHouseBranchData.address.value" maxlength="200"></b-input>
        </b-field>

        <b-field
             horizontal
             :type="exHouseBranchData.country_code.class"
             :message="exHouseBranchData.country_code.message">
          <template slot="label">Country <span class="has-text-danger">*</span></template>
          <b-select @blur="exHouseBranchData.country_code.blur()" v-model="exHouseBranchData.country_code.value" placeholder="Select a Country" >
            <option :value="''"  disabled selected>Select a Country</option>
            <option v-for="country in countryList" :value="country.code" :key="country.code">{{ country.name }}</option>
          </b-select>
        </b-field>

        <b-field
            horizontal
            :type="exHouseBranchData.ios_url.class"
            :message="exHouseBranchData.ios_url.message">
          <template slot="label">IOS App Link <span class="has-text-danger"></span></template>
          <b-input @blur="exHouseBranchData.ios_url.blur()" v-model="exHouseBranchData.ios_url.value" maxlength="200"></b-input>
        </b-field>

        <b-field
            horizontal
            :type="exHouseBranchData.android_url.class"
            :message="exHouseBranchData.android_url.message">
          <template slot="label">Android App Link <span class="has-text-danger"></span></template>
          <b-input @blur="exHouseBranchData.android_url.blur()" v-model="exHouseBranchData.android_url.value" maxlength="200"></b-input>
        </b-field>

        <b-field horizontal>
          <div class="block"></div>
        </b-field>

        <b-field
            horizontal
            message=" ">
          <template slot="label">Status <span class="has-text-danger">*</span></template>
          <div class="block">
            <b-radio v-model="exHouseBranchData.disabled.value"

                     name="status"
                     native-value="0">
              Active
            </b-radio>
            <b-radio v-model="exHouseBranchData.disabled.value"
                     name="status"
                     native-value="1"
                     type="is-danger">
              Not Active
            </b-radio>

          </div>
        </b-field>

        <b-field>
          <b-field label="Geo-Location" label-position="on-border">
            <gmap-map
                :center='mapCenter'
                :zoom='12'
                style='width:100%;  height: 400px;'>
              <gmap-marker
                  :position='mapCenter'
                  :clickable="true"
                  :draggable="true"
                  @drag="updateCoordinates"
              />
            </gmap-map>
          </b-field>
        </b-field>

      </section>
    </div>

    <footer class="card-footer">
      <div class="columns" style="width: 100%">
        <div class="column">
          <p class="column has-text-danger is-italic">Fields marked with <big>*</big> are mandatory</p>
        </div>
        <div class="column">
          <div class="buttons is-pulled-right" style="margin: 5px">
            <b-button @click="validateAndSubmit()" :loading="false" type="is-success">Save</b-button>
            <b-button @click="$router.go(-1)"  type="is-danger">Cancel</b-button>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
import NetworkManager from "@/network"
import {FieldValidator} from "@/helpers/validators/fieldValidator"
import {gmapApi} from 'vue2-google-maps'

export default {
  name: "EditExchangeHouseBranch",
  data() {
    return {
      countryList: [],
      exHouseBranchId: 0,
      mapCenter: {
        lat: 7.083663149349394,
        lng: 80.25686344504543
      },

      exHouseBranchData: {
        name: new FieldValidator(
            "",
            "Name can only have alphanumeric characters and should be at least 3 characters long"
        ),
        contact: new FieldValidator(
            "",
            "please enter a valid contact number"
        ),
        website: new FieldValidator(
            "",
            "Please enter a valid URL (http://somesite.somedomain, https://somesite.somedomain)"
        ),
        address: new FieldValidator(
            "",
            "Please enter a valid address"
        ),
        country_code: new FieldValidator(
            "",
            "Please select a country"
        ),
        ios_url: new FieldValidator(
            "",
            "Please enter a valid URL (http://somesite.somedomain, https://somesite.somedomain)"
        ),
        android_url: new FieldValidator(
            "",
            "Please enter a valid URL (http://somesite.somedomain, https://somesite.somedomain)"
        ),
        location_lat: new FieldValidator(
            "",
            "Please select a location"
        ),
        location_long: new FieldValidator(
            "",
            "Please select a location"
        ),
        disabled: new FieldValidator()
      },

      checkBoxVal: false
    }
  },

  computed: {
    google: gmapApi
  },

  methods: {

    updateCoordinates:function (location){
      this.exHouseBranchData.location_lat.value = location.latLng.lat()
      this.exHouseBranchData.location_long.value = location.latLng.lng()
    },

    initialData: function (){
      const self = this

      self.showFullScreenLoader()

      NetworkManager.apiRequest('api/exchangeHouseBranch/initial-create-data', {}, function (e){
        if(e.statusCode === 200){
          self.countryList = e.data.countries

          self.getForEdit()
        }
      })
    },

    getForEdit: function (){
      const self = this

      const data = {
        id : self.$route.params.id
      }

      NetworkManager.apiRequest('api/exchangeHouseBranch/get-for-edit', data, function (e){
        if(e.statusCode === 200){
          const exchangeHouseBranch = e.data.exchangeHouseBranch
          self.exHouseBranchId = exchangeHouseBranch.id
          self.exHouseBranchData.name.value = exchangeHouseBranch.name
          self.exHouseBranchData.contact.value = exchangeHouseBranch.contact
          self.exHouseBranchData.website.value = exchangeHouseBranch.website
          self.exHouseBranchData.country_code.value = exchangeHouseBranch.country_code
          self.exHouseBranchData.address.value = exchangeHouseBranch.address
          self.exHouseBranchData.ios_url.value = exchangeHouseBranch.ios_url
          self.exHouseBranchData.android_url.value = exchangeHouseBranch.android_url
          self.exHouseBranchData.location_lat.value = exchangeHouseBranch.location_lat
          self.exHouseBranchData.location_long.value = exchangeHouseBranch.location_long
          self.exHouseBranchData.disabled.value = exchangeHouseBranch.disabled

          self.mapCenter = {
            lat: exchangeHouseBranch.location_lat * 1,
            lng: exchangeHouseBranch.location_long * 1
          }
        }

        self.hideFullScreenLoader()
      })
    },

    validate: function (){
      const self = this
      let isValid = true

      Object.keys(self.exHouseBranchData).forEach(function(key, index) {
        console.log(self.exHouseBranchData[key].validate())
         if(!self.exHouseBranchData[key].validate()) isValid = false
      })

      return isValid
    },

    validateAndSubmit: function (){
      const self = this

      if(self.validate()){
        const data = {
          id : self.exHouseBranchId,
          name : self.exHouseBranchData.name.value,
          contact : self.exHouseBranchData.contact.value,
          website : self.exHouseBranchData.website.value,
          address : self.exHouseBranchData.address.value,
          country_code : self.exHouseBranchData.country_code.value,
          ios_url : self.exHouseBranchData.ios_url.value,
          android_url : self.exHouseBranchData.android_url.value,
          location_lat : self.exHouseBranchData.location_lat.value + "",
          location_long : self.exHouseBranchData.location_long.value + "",
          disabled : self.exHouseBranchData.disabled.value
        }

        self.showFullScreenLoader()

        NetworkManager.apiRequest('api/exchangeHouseBranch/edit', data, function (e){
          if(e.statusCode === 200){
            const params = {
              type: 'is-success',
              message: 'Exchange House Branch sent for approval'
            }
            self.$buefy.toast.open(params)
            self.$router.go(-1)
          }
          else {
            const params = {
              type: 'is-warning',
              message: 'Exchange House Branch save failed!.'
            }
            self.$buefy.toast.open(params)
          }

          self.hideFullScreenLoader()
        })
      }
    }
  },

  mounted() {
    this.exHouseBranchData.disabled.value = 0
    this.exHouseBranchData.name.regex = this.regex.nameValidation
    this.exHouseBranchData.contact.regex = this.regex.contactValidation
    this.exHouseBranchData.website.regex = this.regex.websiteValidation
    this.exHouseBranchData.address.regex = this.regex.addressValidation
    this.exHouseBranchData.country_code.regex = this.regex.singleWordValidation
    this.exHouseBranchData.ios_url.regex = this.regex.emptyOrWebsite
    this.exHouseBranchData.android_url.regex = this.regex.emptyOrWebsite
    this.exHouseBranchData.location_lat.regex = this.regex.decimalValidation
    this.exHouseBranchData.location_long.regex = this.regex.decimalValidation

    this.initialData()
  }
}
</script>

<style scoped>
  .product-image-input {
    display: inline-block;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 0.5rem;
    margin-bottom: 0.35rem;
  }

  label.product-image-preview {
    background-size: cover;
    background-position: center;
    top: 0;
    left: 0;
    cursor: pointer;
    display: block;
    width: 100%;
  }

  .product-image-edit-or-delete-bg {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: #f9f9f97d;
    align-items: center;
    justify-content: center;
  }

  .product-image-input:hover .product-image-edit-or-delete-bg {
    display: flex;
  }

  .product-image-edit-or-delete {
    display: flex;
    flex-direction: row;
  }

  .form-preview .label {
    color: #bd9ea5;
  }

  img#image-to-be-cropped {
    max-height: calc(100vh - 150px);
  }
</style>
